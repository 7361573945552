
#D_droite_visionneuse{
    flex:1;
    padding-bottom: 3rem;
    background-color: #f5f5f5;
}

#D_droite_visionneuse.fullSize{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:3;
}

.listedoc #D_droite_visionneuse{
    height:calc(100% - 2.5rem);
    padding-bottom: 0px;
    flex:1;
}

#D_droite_visionneuse_head{
    background-color:#5a95aa;
    color:white;
    /*text-align:center;*/
    max-height:2rem;
    min-height: 2rem;
}


.LIST #D_droite_visionneuse_head,
.WORKFILE #D_droite_visionneuse_head{
    background-color:#3949ab;
}

.ALERTE #D_droite_visionneuse_head{
    background-color:#D84315;
}

.FAMPAT #D_droite_visionneuse_head,
.sim #D_droite_visionneuse_head,
.semantic #D_droite_visionneuse_head{
    background-color: #5a95aa;
}

#D_droite_visionneuse_milieu_rotate{
    position: absolute;
    left:0.5rem;
    bottom:0.5rem;
}

#D_droite_visionneuse_head_tour_moins{
    width:1.5rem;
    height:1.5rem;
    background-image: url(/images/visioneuse/rotation_anti_horaire.svg);
	background-repeat:no-repeat;
	background-position: center;
    background-size:1.5rem 1.5rem;
    cursor: pointer;
}

.WORKFILE #D_droite_visionneuse_head_tour_moins, .LIST #D_droite_visionneuse_head_tour_moins{
    background-image: url(/images/visioneuse/rotation_anti_horaire_list.svg);
}

.ALERTE #D_droite_visionneuse_head_tour_moins{
    background-image: url(/images/visioneuse/rotation_anti_horaire_alerte.svg);
}

#D_droite_visionneuse_head_tour_plus{
    margin-top:0.5em;
    width:1.5rem;
    height:1.5rem;
    background-image: url(/images/visioneuse/rotation_horaire.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
    cursor: pointer;
}

.WORKFILE #D_droite_visionneuse_head_tour_plus, .LIST #D_droite_visionneuse_head_tour_plus{
    background-image: url(/images/visioneuse/rotation_horaire_list.svg);
}

.ALERTE #D_droite_visionneuse_head_tour_plus{
    background-image: url(/images/visioneuse/rotation_horaire_alerte.svg);
}

#D_droite_visionneuse_head_centre{
    display:inline-block;
}

#D_droite_visionneuse_head_flex{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#D_droite_visionneuse_head_centre_prec{
    width:1rem;
    height:2rem;
    background-image: url(/images/visioneuse/prec.svg);
	background-repeat:no-repeat;
	background-position: center;
    background-size:0.8rem 0.8rem;
    margin-left:0.5rem;
    cursor: pointer;
}

#D_droite_visionneuse_head_centre_pos{
    font-size:1rem;
    margin-left:0.5rem;
}

#D_droite_visionneuse_head_tours_next{
    width:1rem;
    height:2rem;
    background-image: url(/images/visioneuse/next.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:0.8rem 0.8rem;
    cursor: pointer;
}

#D_droite_visionneuse_head_full_screen{
    float:right;
    width:2rem;
    height:2rem;
    background-image: url(/images/full_screen.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:2rem 2rem;
    cursor: pointer;
}

#D_droite_visionneuse_head_full_over_onglet{
    float:right;
    width:2rem;
    height:2rem;
    background-image: url(/images/full_new_tab.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:2rem 2rem;
    cursor: pointer;
}

#D_droite_visionneuse_head_close{
    float:right;
    width:2rem;
    height:2rem;
    background-image: url(/images/close_blanc.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:2rem 2rem;
    cursor: pointer;
    display:none;
}

.listedoc #D_droite_visionneuse_head_close{
    display: initial;
}


#D_droite_visionneuse_milieu{  
    background-color: #f5f5f5;
    height:calc(100% - 10rem);
    text-align:center;
    position:relative;
    overflow: hidden;
}

#D_droite_visionneuse_milieu_image{
    display:inline-block;
    margin-top:1%;
    width:98%;
    height:98%;
    background-image: url(/images/doc_image.png);
	background-repeat:no-repeat;
	background-position: center;
	background-size:contain;
}

#D_droite_visionneuse_milieu_zoom{
    position:absolute;
    bottom:0.5rem;
    right:0.5rem;
}

#D_droite_visionneuse_milieu_zoom_in{
    width:1.5rem;
    height:1.5rem;
    background-color: transparent;
    background-image: url(/images/visioneuse/plus.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
    border-radius:1rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.WORKFILE #D_droite_visionneuse_milieu_zoom_in, .LIST #D_droite_visionneuse_milieu_zoom_in{
    background-image: url(/images/visioneuse/plus_list.svg);
}

.ALERTE #D_droite_visionneuse_milieu_zoom_in{
    background-image: url(/images/visioneuse/plus_alerte.svg);
}

#D_droite_visionneuse_milieu_zoom_out{
    width:1.5rem;
    height:1.5rem;
    background-color: transparent;
    background-image: url(/images/visioneuse/moins.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
    border-radius:1rem;
    cursor: pointer;
}

.WORKFILE #D_droite_visionneuse_milieu_zoom_out, .LIST #D_droite_visionneuse_milieu_zoom_out{
    background-image: url(/images/visioneuse/moins_list.svg);
}

.ALERTE #D_droite_visionneuse_milieu_zoom_out{
    background-image: url(/images/visioneuse/moins_alerte.svg);
}

#D_droite_visionneuse_bas{
	display:flex;
	flex-direction: row;
	align-items:top;
	max-width:100%;
	overflow-x:auto;
	background-color: #f5f5f5;
}

.D_droite_visionneuse_bas_image{
    box-sizing: border-box;
    min-width: 33%;
	min-height:7rem;
	background-image: url(/images/doc_image.png);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center; 
	background-color: white;
	cursor:pointer;
}

.LIST .D_droite_visionneuse_bas_image.selected,
.WORKFILE .D_droite_visionneuse_bas_image.selected{
    border:0.1rem solid #3949ab;
}

.ALERTE .D_droite_visionneuse_bas_image.selected{
    border:0.1rem solid #D84315;
}

.FAMPAT .D_droite_visionneuse_bas_image.selected,
.sim .D_droite_visionneuse_bas_image.selected,
.semantic .D_droite_visionneuse_bas_image.selected{
    border:0.1rem solid #5a95aa;
}

@media (min-width: 812px) and (max-width: 1300px){
    #D_droite_visionneuse_head{
        font-size:0.8rem;
    }
}

@media (max-width: 811px){
    
    #D_droite_visionneuse_head{
        font-size:0.8rem;
    }

    .listedoc #D_droite_visionneuse{
        height:28rem;
    }
}