#root .SM_CPAddMenu_item
{  
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    padding: 0.3rem 0 0.3rem 0.3rem;
}

#root .SM_CPAddMenu_item.rc-menu-item-active{
    background-color: #e8eaf6;
}