.SelectCustom{
    /*min-width: 5rem;*/
}

.selectCustom_select_value_un{
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 0;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    /*cursor:pointer;*/
    padding-left: 2rem;
	background-repeat:no-repeat;
	background-size: 0.9rem;
    background-position: 0.4rem;
	background-image: url(/images/select_multiple_disable.svg);
}

.LIST .selectCustom_select_value_un{
    background-image: url(/images/select_multiple_list.svg);
}


.selectCustom_select_value_un.selected{
	background-image: url(/images/select_multiple_check.svg);
}

.LIST .selectCustom_select_value_un.selected{
	background-image: url(/images/select_multiple_check_list.svg);
}

.ant-select-dropdown-menu-item.defaultColor .selectCustom_select_value_un,
.ant-select-dropdown-menu-item.disable .selectCustom_select_value_un{
	background-image: url(/images/select_multiple_disable.svg);
}

.ant-select-dropdown-menu-item.defaultColor .selectCustom_select_value_un.selected,
.ant-select-dropdown-menu-item.disable .selectCustom_select_value_un.selected{
	background-image: url(/images/select_multiple_check_disable.svg);
}

.selectCustom_select_value_un.disable{
    pointer-events: none;
    cursor: default;
}

body .ant-select-dropdown-menu-item{
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding:0;
    background-color: white;
}

.ant-select-dropdown-menu-item-active{
    background-color:#74a9bd !important;
}

.ant-select-dropdown-menu-item-active .selectCustom_select_value_un{
    background-image: url(/images/select_multiple_white.svg);
}

.LIST .ant-select-dropdown-menu-item-active,
.WORKFILE .ant-select-dropdown-menu-item-active{
    background-color:#3f51b5 !important;
}

.ALERTE .ant-select-dropdown-menu-item-active{
    background-color:#e64a19 !important;
}

.ant-select-dropdown-menu-item-active.disable{
    background-color:white !important;
    cursor: default;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .anticon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .anticon{
    display:none !important;
}

.ant-select-dropdown-menu-item-selected{
    background-color: transparent;
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
     padding-left: 12px; 
}

.selectCustom_select_value_un_label{
	font-size         : 0.8rem;
    font-weight       : normal;
    font-style        : normal;
    font-stretch      : normal;
    line-height       : normal;
    letter-spacing    : normal;
    color: #212121;
}

.selectCustom_select_value_un_info{  
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.ant-select-dropdown-menu-item-group-title{
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
    height: 1.8rem;
    padding: 0.5rem 0 0.5rem 1.2rem;
}

.ant-select-dropdown-menu-item-group{
    border-top: solid 1px #e0e0e0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ant-select-dropdown-menu-item-group:first-child{
    border-top: none;
}

.selectCustom_select_value_un:hover,
.selectCustom_select_value_un:hover .selectCustom_select_value_un_info,
.selectCustom_select_value_un:hover .selectCustom_select_value_un_label
{
    color:white;
}

.ant-select-dropdown-menu-item-active.disable .selectCustom_select_value_un:hover,
.ant-select-dropdown-menu-item-active.disable .selectCustom_select_value_un:hover .selectCustom_select_value_un_info,
.ant-select-dropdown-menu-item-active.disable .selectCustom_select_value_un:hover .selectCustom_select_value_un_label,
.ant-select-dropdown-menu-item.disable .selectCustom_select_value_un,
.ant-select-dropdown-menu-item.disable .selectCustom_select_value_un .selectCustom_select_value_un_info,
.ant-select-dropdown-menu-item.disable .selectCustom_select_value_un .selectCustom_select_value_un_label
{
    color: #9e9e9e;
}

.ant-select-open .ant-select-selection{
    border:none;
}

.ant-select-selection{
    border:0;
    cursor:pointer;
}

.ant-select-selection__placeholder{
    font-family: 'roboto';
	font-size         : 0.8rem;
    font-weight       : normal;
    font-style: oblique;
    font-stretch      : normal;
    line-height       : 1.15rem;
    letter-spacing    : normal;
    margin-left:0 !important;
    color:#9e9e9e;
}

.inactif .ant-select-selection__placeholder{
    color:#9e9e9e;
    font-style: oblique;
}

.inactif .ant-select-selection{
    background-color: #eeeeee;
}

.ant-select-search__field{
    box-shadow: none !important;
}

.ant-select-selection{
    box-shadow: none !important;
}

.ant-select-selection-selected-value{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    color:#212121;
    margin-right:0.2rem;
}
.inactif .ant-select-selection-selected-value,
.inactif .ant-select-selection__choice{
    color:#9e9e9e;
    background-color: #eeeeee;
}

.ant-select-selection__choice__content{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#212121;
    margin-top: 0.15rem;
}

.ant-select-selection--single .ant-select-selection__rendered{
    margin-left:5px;
}

.ant-select-arrow{
    margin-top: -0.6rem;
}

.ant-select-sm .ant-select-arrow{
    margin-top: -0.3rem;
}

.ant-select{
    padding: 1px 0 0 0;
}