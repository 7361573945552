#D_head{
	display:flex;
	flex-flow: row-reverse;
	align-items: center;  
	border: solid 0.05rem #e0e0e0;
	background-color: #fafafa;
	height:3.4rem;
	font-size:1rem;
	color: #13275c;
	box-sizing: border-box;
}

.recherchesimple #D_head{
	background-color: #ffffff;
	border: 0;
}

#D_head_permalink{
	display:flex;
	align-items:center;
	background-color: rgba(250, 250, 250, 1);
	border: 0.1rem solid rgba(204, 204, 204, 1);
	height:3rem;
	font-size:0.8rem;
	margin-bottom:1rem;
}
/*
entete
*/

.select_bouton_head{
	border-bottom: 0.2rem solid rgba(121, 121, 121, 1);
	text-decoration: underline;
	font-weight: bold;
}

#S_orbit{
	display:inline-block;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color:#13275c;
	font-size:1.5rem;
	margin:0 0.15rem 0 0.3rem;
	cursor: pointer;
}

#S_express{  
	margin-right:auto;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #5a95aa;
	font-size:1.5rem;
	cursor: pointer;
}

#I_logo_questel{
	height:2.5rem;
	margin-left:0.3rem;
	cursor: pointer;
}

.recherchesimple #I_logo_questel, .recherchesimple #S_express, .recherchesimple #S_orbit{
	display:none;
}



#D_bouton_mes_projets, #D_bouton_mes_alertes{
	margin-top: 0.3rem;;
	margin-right: 0.5rem;
	height:100%;
	display:flex;
	align-items:center;
	cursor:pointer;
	border-bottom: 0.4rem solid transparent;
	box-sizing: border-box;
}

#D_bouton_mes_projets{
	min-width: 7rem;
}

#D_bouton_mes_alertes{
	min-width: 6rem;
}


.ALERTE #D_bouton_mes_alertes{
	border-bottom: 0.4rem solid #D84315;
	color: #D84315;
	font-weight: bold;
}



#D_bouton_mes_projets.select_bouton_project,
.LIST #D_bouton_mes_projets,
.WORKFILE #D_bouton_mes_projets{
	border-bottom: 0.4rem solid #3949ab;
	color: #3949ab;
	font-weight: bold;
}

#S_mes_projets, #S_mes_alertes{
	font-size:1.15rem;
	padding-top: 0.3rem;
}

#I_mes_projets, #I_mes_alertes{
	margin-right:0.5rem;
	height: 1.7rem;
	width:1.5rem;
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
}

#I_mes_projets{
	background-image: url(/images/projets.svg);
}

.LIST #I_mes_projets,
.WORKFILE #I_mes_projets{
	background-image: url(/images/projets-sel.svg);
}

#I_mes_alertes{
	background-image: url(/images/alertes.svg);
}

.ALERTE #I_mes_alertes{
	background-image: url(/images/alertes-sel.svg);
}

#D_recherche_head{
	margin:auto;
}

#D_recherche_back_to_search{
	display:flex;
	flex-direction: row;
	align-items: center;
	margin-right:3rem;
	margin-left:3rem;
	font-size: 1.5rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #5a95aa;
	cursor: pointer;
}

#D_recherche_back_to_search_img{
	width:1.5rem;
	height:1.5rem;
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
	background-image: url(/images/inf_fampat.svg);
	margin-right:0.5rem;
}

#D_recherche_head_input{
	box-sizing: border-box;
	height:2.2rem;
	width:34rem;
	font-size: 1.1rem; 
	 border: solid 0.01rem #bdbdbd;
	 padding-left: 0.5rem;

}

#D_recherche_lance{
	float:right;
	box-sizing: border-box;
	border: 0;
	background-color: #5a95aa;
	height:2.2rem;
	width:4.5rem;
	background-image: url(/images/recherche_blanche.png);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1rem 1rem;
	cursor:pointer;
}

#I_recherche_click{
	float:right;
	height:1.5rem;
}

#D_gestion_user{
	margin-left: 0.5rem;
	margin-right:0.5rem;
	cursor:pointer;
}

#D_gestion_user_pipe{
	border: 1px solid #e0e0e0;
	height: 1.7rem;
}

#D_recherche_pipe{
	margin-right:1rem;
	border: 1px solid #e0e0e0;
	height: 1.7rem;
}

.recherchesimple #D_recherche_pipe{
	display:none;
}




@media (min-width: 916px) and (max-width: 1300px){
	#I_logo_questel{
		margin-right: auto;
	}

	#S_orbit{
		display:none;
	}
		
	#S_express{
		display:none;
	}
	
	#I_mes_projets, #I_mes_alertes{
		margin-right:1rem;
	}
	
	#D_bouton_mes_projets{
		margin-left:2rem;
	}

	#D_recherche_head_input{
		width:20rem;
		height:2.25rem;
	}

	#D_recherche_lance{
		height:2.25rem;
		background-size:1rem 1rem;
	}

	#I_recherche_click{
		height:1rem;
	}		
}

@media (min-width: 646px) and (max-width: 915px){
	
	#I_logo_questel{
		margin-right: auto;
	}

	#S_orbit{
		display:none;
	}
		
	#S_express{
		display:none;
	}
	
	#S_mes_projets{
		display:none;
	}

	#S_mes_alertes{
		display:none;
	}

	
	#D_bouton_mes_projets{
		min-width: 3rem;
	}

	#D_bouton_mes_alertes{
		min-width: 3rem;
	}
	
	#I_mes_projets, #I_mes_alertes{
		margin-right:1rem;
	}

	#D_recherche_head_input{
		width:10rem;
		height:2.25rem;
	}

	#D_recherche_lance{
		height:2.25rem;
		background-size:1rem 1rem;
	}

	#I_recherche_click{
		height:1rem;
	}
	
}

@media (max-width: 645px){

#S_orbit{
		display:none;
	}
		
	#S_express{
		display:none;
	}
	
	#S_mes_projets{
		display:none;
	}

	#S_mes_alertes{
		display:none;
	}

	#D_bouton_mes_projets{
		min-width: 3rem;
	}

	#D_bouton_mes_alertes{
		min-width: 3rem;
	}
	
	#I_recherche, #I_mes_projets, #I_mes_alertes{
		margin-right:0.2rem;
	}

	#D_bouton_mes_projets ,#D_bouton_mes_alertes {
		margin-left: 0.3rem;
	}

	

	#D_recherche_head_input{
		width:6rem;
		height:2rem;
	}

	#D_recherche_head{
		margin-left:0.5rem;
	}

	#D_recherche_lance{
		height:2.05rem;
		background-size:1rem 1rem;
	}

	#I_recherche_click{
		height:1rem;
	}

}