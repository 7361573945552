#D_droite{
	flex:2.1;
	display:flex;
	flex-direction: column;
	align-items:center;
	height:100%;
	border-left: 3px solid #eeeeee;
	position: relative;
	z-index:1;
}

#D_droite.front{
	z-index:7;
}

#D_droite:focus{
	outline: none;
}

.listedoc #D_droite{
	border-left:0;
	padding-left: 0.5rem;
}

.search-selector-tab {
	position: absolute;
	right: 20px;
	user-select: none;
	z-index:2;
}

.search-selector-tab .search-mode-link{
	margin-left: 10px;
	line-height: 30px;
	font-size  : 12px;
}

#D_droite_affichage{
	display:flex;
	flex-direction: row;
	align-items:top;
	height: calc( 100% - 3rem);
	max-height: calc( 100% - 3rem);
	width:100%;
	max-width: 100%;
}

.listedoc #D_droite_affichage{
	height:100%;
	max-height: 100%;
}

#D_centreRecherche{
	width:100%;
	height:calc( 100% - 5rem);
	display: flex;
	flex-direction: column;
	flex:auto;
}

#D_centreRecherche.loadgif > div{
	pointer-events: none;
	opacity: 0.4;
}


#D_recherche_milieu{
	width:100%;
	height:100%;
	display: flex;
	flex-direction: row;
}

#D_recherche_centre_et_droite{
	flex:4.7;
	display:flex;
	flex-direction: column;
	height: 100%;
}

.rechercheplus #D_recherche_centre_et_droite{
	display:none;
}

#D_recherche_centre_et_droite_choix_p_npl{
	display:flex;
	flex-direction: row;
	height: 2rem;
	border-bottom: 1px solid #eeeeee;
}

.listedoc #D_recherche_centre_et_droite_choix_p_npl{
	display:none;
}

#D_recherche_centre_et_droite_infos{	
	display:flex;
	flex-direction: row;
	height: calc(100% - 2rem);

}

#D_recherche_centre_et_droite_choix_p, #D_recherche_centre_et_droite_choix_npl{
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #9e9e9e;
	margin:auto 0 0 4rem;
	padding: 0 0.3rem 0.15rem 0.3rem;
	border-bottom: 0.25rem solid transparent;
	cursor:pointer;
}

#D_recherche_centre_et_droite_choix_p:hover, #D_recherche_centre_et_droite_choix_npl:hover{
	color: #212121;
}

.FAMPAT #D_recherche_centre_et_droite_choix_p.select,
.sim #D_recherche_centre_et_droite_choix_p.select,
.semantic #D_recherche_centre_et_droite_choix_p.select{
	border-bottom-color: #5a95aa;
}
.LIST #D_recherche_centre_et_droite_choix_p.select,
.WORKFILE #D_recherche_centre_et_droite_choix_p.select{
	border-bottom-color: #3949ab;
}
.ALERTE #D_recherche_centre_et_droite_choix_p.select{
	border-bottom-color: #D84315;
}


.FAMPAT #D_recherche_centre_et_droite_choix_npl.select,
.sim #D_recherche_centre_et_droite_choix_npl.select,
.semantic #D_recherche_centre_et_droite_choix_npl.select{
	border-bottom-color: #5a95aa;
}
.LIST #D_recherche_centre_et_droite_choix_npl.select,
.WORKFILE #D_recherche_centre_et_droite_choix_npl.select{
	border-bottom-color: #3949ab;
}
.ALERTE #D_recherche_centre_et_droite_choix_npl.select{
	border-bottom-color: #D84315;
}

#D_recherche_gauche{
	display: flex;
	flex-direction: column;
	flex:1;
	border-right: 3px solid #eeeeee;
}

.listedoc #D_recherche_gauche{
	display:none;
}

.rechercheplus #D_recherche_gauche{
	border:none;
}

#D_recherche_gauche_bas{
	display: flex;
	flex-direction: row;
	max-height: 100%;
	height: 100%;
}

.LIST #D_recherche_gauche_bas,
.WORKFILE #D_recherche_gauche_bas,
.ALERTE #D_recherche_gauche_bas,
.sim #D_recherche_gauche_bas,
.semantic #D_recherche_gauche_bas {
	max-height: calc(100% - 2.2rem);
}

#D_recherche_gauche_infos{
	width: 100%;
	display: flex;
	flex-direction: row;
	padding:0.5rem 0 0.5rem 0;
	font-size:	1.5rem;
	border-bottom: 1px solid #eeeeee;
}

#D_recherche_gauche_infos_retour{
	font-size:0.8rem;
	display:flex;
	flex-direction: row;
	align-items: center;
	margin-left: 1rem;
	flex:1;
	cursor:pointer;
	color:#74a9bd;
}

#D_recherche_gauche_infos_emplacement{
	flex:1;
	color:#74a9bd;
	font-size:1rem;
}

.LIST #D_recherche_gauche_infos_retour,
.WORKFILE #D_recherche_gauche_infos_retour,
.LIST #D_recherche_gauche_infos_emplacement,
.WORKFILE #D_recherche_gauche_infos_emplacement{
	color:#3949ab;
}

.ALERTE #D_recherche_gauche_infos_retour,
.ALERTE #D_recherche_gauche_infos_emplacement{
	color:#D84315;
}


#D_recherche_gauche_infos_retour_img_projet{
	background-image: url(/images/inf_projet.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center; 
	width:0.8rem;
	height:0.8rem;
	margin-right:0.5rem;
}

#D_recherche_gauche_infos_retour_img_alerte{
	background-image: url(/images/inf_alerte.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center; 
	width:0.8rem;
	height:0.8rem;
	margin-right:0.5rem;
}

#D_recherche_gauche_infos_retour_img_fampat{
	background-image: url(/images/inf_fampat.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center; 
	width:0.8rem;
	height:0.8rem;
	margin-right:0.5rem;
}

#D_graphe_icone{
	position: absolute;
	right:calc(50% - 1.5rem);
	height:3rem;
	cursor:pointer;
}

#D_graphe_icone #D_graphe_icone_image{
	position: absolute;
	background-image: url(/images/graphe.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center; 
	width:3rem;
	height:3rem;
	margin: auto;
	z-index: 2;
}

#D_graphe_icone.graphe_open{
	top:2rem;
	width:3rem;
}

#D_graphe_icone.graphe_close{
	bottom: 0rem;
	top:unset;
	left:1%;
	right: 0px;
	width:95%;
}

#D_graphe_icone.graphe_open #D_graphe_icone_fond{
	display: none;
}

#D_graphe_icone.graphe_close #D_graphe_icone_image{
	left:48%;
}

#D_graphe_icone #D_graphe_icone_fond{
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: none;
	background-color: white;
	border-radius: 7px 7px 0 0 ;
	height: 55%;
	width: 99%;
	right:0px;
	transform: translateY(77%) translateX(0%);
	z-index: 1;
}

@media (max-width: 1300px){
#D_droite_affichage{
	max-width: 99%;
}
}

@media (max-width: 811px){
	
	.rechercheavancee #D_droite{
		display:none;
	}
			
	.rechercheavancee #D_droite_close{
		display:none;
	}

    .rechercheavancee #D_droite_affichage{
        flex-direction:column;
	}
	
	.listedoc #D_droite_affichage{
		flex-direction:column-reverse;
		height:initial;
		max-height: initial;
	}

	.listedoc #D_droite{
		height:initial;
		display: inline-block;
		overflow-y: auto;
	}

	.search-selector-tab{
		position: static;
	}
}
