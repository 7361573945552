html{
	font-size:100%;
    height:100%;
}

body{
	font-family:'Roboto', sans-serif;
	font-size: 12px;
    height:100%;
}

body #root{
	height:100%;
}

#D_tous{
	display:flex;
	flex-direction: column;
	height:100%;
    margin:0;
    padding:0;
}

.loadgif{
	background-image: url(/images/load.gif);
	background-repeat:no-repeat;
	background-size: 4rem;
	background-position: center;
	min-height:1rem;
}

.react-contexify {
	z-index: 2;
}