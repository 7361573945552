.treebeardmenu{
    position:absolute;
    background-color: white;  
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    width: max-content;
    z-index: 99;
}

.treebeardmenu:focus{
    outline: none;
}

.treebeardmenu_item{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    padding:0.5rem;
}

.treebeardmenu_item:hover{
    background-color: #e8eaf6;
}

.B_treebeard_menu{
    display: flex;
    align-items: center;
    justify-content: center;
	background-image: url(/images/menu/menubuttonblue.svg);
	background-repeat:no-repeat;
	background-size:2.1rem;
	background-position: center;
    height:2.1em;
    width:2.1rem;
}

.B_treebeard_menu:hover{
    background-image: url(/images/menu/menubuttonbluehover.svg);
}