.D_DialogProjetDeleteDoc #I_Dialog_lib{
    width:100%;
}

.D_DialogProjetDeleteDoc #I_Dialog_comment{
    width:100%;
    height:10rem;
}

.D_DialogProjetDeleteDoc .S_lib{
    color:#3949ab;
}

.D_DialogProjetDeleteDoc .rc-dialog-title{
    color:#3949ab;
}

.D_DialogProjetDeleteDoc .rc-dialog-header{
    background-color: #fafafa;
}

.D_DialogProjetDeleteDoc .rc-dialog-close-x{
    color:#3949ab;
}

.D_DialogProjetDeleteDoc .D_buttons{
    display:flex;
    flex-direction: row;
}

.D_DialogProjetDeleteDoc .B_valider{
    background-color: #3949ab;
    border-radius: 3px;
    color:white;
    font-size: 0.9rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
}

.D_DialogProjetDeleteDoc .B_cancel{
    color:#3949ab;
    font-size: 0.9rem;
    margin-right: 2rem;
}

