#D_droite_select_affichage{
	display:flex;
	flex-direction: row;
	align-items:center;
	justify-content: flex-start;
	margin-left: 0;
	width:95%;
	margin: 1rem 0 0.5rem 0;
	box-sizing: content-box;
}

#D_droite_select_affichage.borderbottom{
	border-bottom: solid 0.1rem #e0e0e0;
}

.listedoc #D_droite_select_affichage{
	display:none
}

#D_droite_select_affichage_stats, #D_droite_select_affichage_pictures, #D_droite_select_affichage_document{
	font-size     : 12px;
	padding-bottom: 0.3rem;
	margin        : 0 1rem 0 1rem;
	cursor        : pointer;
	color         : #888888;
	border-bottom : 0.25rem solid transparent;
}

#D_droite_select_affichage_document {
	margin-left:2rem;
}

#D_droite_select_affichage_stats.selectSearch,
#D_droite_select_affichage_pictures.selectSearch,
#D_droite_select_affichage_document.selectSearch{
	border-bottom: 0.25rem solid rgba(62, 130, 153, 1);
}

.FAMPAT #D_droite_select_affichage_stats.selectSearch,
.FAMPAT #D_droite_select_affichage_pictures.selectSearch,
.FAMPAT #D_droite_select_affichage_document.selectSearch,
.sim #D_droite_select_affichage_stats.selectSearch,
.sim #D_droite_select_affichage_pictures.selectSearch,
.sim #D_droite_select_affichage_document.selectSearch,
.semantic #D_droite_select_affichage_stats.selectSearch,
.semantic #D_droite_select_affichage_pictures.selectSearch,
.semantic #D_droite_select_affichage_document.selectSearch{
	border-bottom: 0.25rem solid rgba(62, 130, 153, 1);
}

.LIST #D_droite_select_affichage_stats.selectSearch,
.LIST  #D_droite_select_affichage_pictures.selectSearch,
.LIST  #D_droite_select_affichage_document.selectSearch,
.WORKFILE #D_droite_select_affichage_stats.selectSearch,
.WORKFILE  #D_droite_select_affichage_pictures.selectSearch,
.WORKFILE  #D_droite_select_affichage_document.selectSearch {
	border-bottom: 0.25rem solid #3949ab;
}

.ALERTE #D_droite_select_affichage_stats.selectSearch,
.ALERTE  #D_droite_select_affichage_pictures.selectSearch,
.ALERTE  #D_droite_select_affichage_document.selectSearch {
	border-bottom: 0.25rem solid #D84315;
}

.selectAlert{
	font-weight: bold;
	border-bottom: 0.25rem solid #D84315;
}