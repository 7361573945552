
#D_droite_document{
	height:calc(100% - 3rem);
	max-height:calc(100% - 3rem);
	overflow-y:auto;
    /*flex:1.5;*/
	padding-right: 0.3rem;
	width:98%;
	max-width: 98%;
    margin-right: auto;
	margin-left: auto;
	padding-bottom: 3rem;
}

.listedoc #D_droite_document{
	height:100%;
	max-height:100%;
	flex:2;
	padding-bottom: 0;
}

#D_dd_titre{
	font-size:1.1rem;
	margin: 0.2rem 0.5rem 0.5rem 0.3rem;
}

#D_dd_info_doc_picture{
	display:flex;
	flex-direction: row;
	align-items:center;
	margin-left:0.3rem;
}

.listedoc #D_dd_info_doc_picture{
	height: calc(100% - 2.5rem);
	max-height: calc(100% - 2.5rem);
}

#D_dd_info_doc{
	flex:1.5;
	display:flex;
	flex-direction: column;
	align-items:center;
	max-width:100%;
	border: 0.01rem solid #E5E5E5;
}

.listedoc  #D_dd_info_doc{
	flex-direction: row;
	height: 100%;
	max-height: 100%;
	border:none;
}

#D_dd_picture{
	flex:0;
	display:flex;
	flex-direction: row;
	align-items:center;
}

#D_dd_info_doc_haut{
	display:flex;
	flex-direction: column;
	max-width:100%;
	width:100%;
}

.listedoc #D_dd_info_doc_haut{
	flex-direction: row-reverse;
	height: 100%;
	max-height: 100%;
}

#D_dd_info_doc_haut_gauche{
	/*flex:1;*/
}

.listedoc #D_dd_info_doc_haut_gauche{
	margin-right: 0.5rem;
	flex:1;
	overflow-y: auto;
	max-height: calc(100% - 2.5rem);
}

#D_dd_info_doc_haut_gauche_commentaire{
	margin-top: 0;
}

#D_dd_info_doc_haut_gauche_commentaire_etoile{
	min-width:6rem;
	height:1rem;
	width:100%;
}

#D_dd_info_doc_haut_gauche_commentaire_etoile>img{
	width:1em;
	cursor: pointer;
}

.D_dd_info_doc_haut_gauche_commentaire_etoile_1{
	margin-left: 0.5rem;
}

#D_dd_info_doc_haut_gauche_commentaire_open{
	background-repeat:no-repeat;
	background-position: center;
	background-size:1rem 1rem;
	margin-right:0.3rem;
	width:1rem;
	height: 1rem;
}

#D_dd_info_doc_haut_gauche_nb_add_open{
	float: right;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#D_dd_info_doc_haut_gauche_commentaire_add{
	margin-right: 2rem;
	color:#3c80f6;
	cursor: pointer;
}

#D_dd_info_doc_haut_gauche_commentaire_nb_comment{
	margin-right: 3rem;
}

.D_dd_info_doc_haut_gauche_commentaire_un{
	margin: 1rem 0 1rem 0;
}

.D_dd_info_doc_haut_gauche_commentaire_un_title{
	font-weight: bold;
	margin-bottom: 0.5rem;
	margin-top: 0.3rem;
}

.D_dd_info_doc_haut_gauche_commentaire_un_comment{
	color: #212121;
}

.D_dd_info_doc_haut_gauche_commentaire_un_createur{
	float: right;
	color: #9e9e9e;
	margin-right: 0.5rem;
	margin-top: 0.3rem;
}

.D_dd_info_doc_haut_gauche_commentaire_un_date{
	float:right;
	color:#9e9e9e;
	margin-right: 0.5rem;
	margin-top: 0.3rem;
}

.D_dd_info_doc_haut_gauche_commentaire_un_edit{
	float: right;
}

#S_dd_info_doc_haut_gauche_abstract,
#S_dd_info_doc_haut_gauche_images{
	font-weight:bold;
}

#P_dd_info_doc_haut_gauche_abstract{
	font-size:0.8rem;
	color:#212121;
    /*word-break: break-all;*/
	hyphens: auto;
	word-break: break-word;
}

#D_dd_info_doc_haut_gauche_images_visionneuse{
	display:flex;
	flex-direction: row;
	align-items:top;
	overflow-x:auto;
	background-color: #f5f5f5;
    min-width: 4rem;
	min-height:6rem;
	max-height: 10rem;
	max-width:99%;
	width:99%;
	margin-top: 0.5rem;
}

.D_dd_info_doc_haut_gauche_images_visionneuse{
	min-width:25%;
	min-height:4rem;
	background-image: url('/images/add.svg');
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center;
	cursor:pointer;
	margin: 0.05rem
}

#D_dd_info_doc_haut_gauche_classification_liste,
#D_dd_info_doc_haut_gauche_classification_liste_CPC{
	max-width:100%;
	margin: 0.5rem 0 0.5rem 0;
	min-height:2rem;
	overflow-y:auto;
	display: flex;
	flex-wrap: wrap;
}
#S_dd_info_doc_haut_gauche_classification{
	margin-top: 1rem;
}

.S_dd_info_doc_haut_gauche_classification_une{
	display:inline-block;
	padding: 0 0.2rem 0 0.2rem;
	font-size:0.8rem;
	color: #545252;
	margin-bottom:0.3rem;
	cursor: pointer;
	color: #3C80F6;
}

.S_dd_info_doc_haut_gauche_classification_une:hover{
	text-decoration: underline;
}


.listedoc #D_dd_info_doc_haut_droite{
	flex:1;
	overflow-y: auto;
	max-height: calc(100% - 2.5rem);
}

#D_dd_info_doc_haut_droite_etage_1,
#D_dd_info_doc_haut_droite_etage_1_NPL{
	color:white;
	padding: 1rem 0 0.5rem 0.5rem;
	position:relative;
}

.FAMPAT #D_dd_info_doc_haut_droite_etage_1,
.sim #D_dd_info_doc_haut_droite_etage_1,
.semantic #D_dd_info_doc_haut_droite_etage_1,
	.FAMPAT #D_dd_info_doc_haut_droite_etage_1_NPL,
.sim #D_dd_info_doc_haut_droite_etage_1_NPL,
.semantic #D_dd_info_doc_haut_droite_etage_1_NPL{
	background-color: #74a9bd;
}

.LIST #D_dd_info_doc_haut_droite_etage_1,
.WORKFILE #D_dd_info_doc_haut_droite_etage_1,
.LIST #D_dd_info_doc_haut_droite_etage_1_NPL,
.WORKFILE #D_dd_info_doc_haut_droite_etage_1_NPL{
	background-color: #3f51b5;
}

.ALERTE #D_dd_info_doc_haut_droite_etage_1,
.ALERTE #D_dd_info_doc_haut_droite_etage_1_NPL{
	background-color: #e64a19;
}

#D_dd_info_doc_haut_droite_etage_1_NPL{
	display:flex;
	flex-direction: row;
	font-size: 0.75rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	padding-bottom: 1rem;
}

#D_dd_info_doc_haut_droite_etage_1_date_pub,
#D_dd_info_doc_haut_droite_etage_1_date_start,
#D_dd_info_doc_haut_droite_etage_1_date_end{
	flex: 1;
}


#S_dd_info_doc_haut_droite_etage_1_date_pub,
#S_dd_info_doc_haut_droite_etage_1_date_start,
#S_dd_info_doc_haut_droite_etage_1_date_end{
	margin-left:0.3rem;
	font-weight: bold;
}

#S_dd_info_doc_haut_droite_etage_1_REP{
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

#D_dd_info_doc_haut_droite_priority_date_label{
    position: absolute;
    right: 2rem;
    margin-top: -1rem;
	display:flex;
	flex-direction: row;
	color:white;
	font-size: 0.7rem;
}

#S_dd_info_doc_haut_droite_etage_3_priority_date_label{
	margin-right: 0.1rem;
}

#S_dd_info_doc_haut_droite_etage_1_gr{
	font-size: 0.8rem;
	display:inline-block;
	margin-top:0.7rem;
}

.WORKFILE #S_dd_info_doc_haut_droite_etage_1_gr{
	visibility:hidden;
}

#D_dd_info_doc_haut_droite_etage_2{
	color:white;
	padding: 0.32rem 0 0.32rem 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

#D_dd_info_doc_haut_droite_etage_2.etage2_NPL{
padding: 0.4rem 0 0.4rem 0.5rem;
}

.FAMPAT #D_dd_info_doc_haut_droite_etage_2,
.sim #D_dd_info_doc_haut_droite_etage_2,
.semantic #D_dd_info_doc_haut_droite_etage_2{
	background-color: #3d8299;
}

.LIST #D_dd_info_doc_haut_droite_etage_2,
.WORKFILE #D_dd_info_doc_haut_droite_etage_2{
	background-color: #1a237e;
}

.ALERTE #D_dd_info_doc_haut_droite_etage_2{
	background-color: #bf360c;
}

#D_dd_info_doc_haut_droite_etage_2_download_pdf_image{
	width:1.5rem;
	height:1.5rem;
	float:left;
	background-image: url(/images/pdf.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1rem 1rem;
	margin-right:0.3rem;
}

#D_dd_info_doc_haut_droite_etage_2_similar{
	margin-left:1rem;
}

#D_dd_info_doc_haut_droite_etage_2_similar_image{
	width:1.5rem;
	height:1.5rem;
	float:left;
	background-image: url(/images/similar.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1rem 1rem;
	margin-right:0.3rem;
}


#D_dd_info_doc_haut_droite_etage_2_prio_art{
	margin-left:1rem;
}

#D_dd_info_doc_haut_droite_etage_2_prior_art_image{
	width:1.5rem;
	height:1.5rem;
	float:left;
	background-image: url(/images/prior-art.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:1rem 1rem;
	margin-right:0.3rem;
}

 #D_dd_info_doc_haut_droite_etage_2_download_pdf,
 #D_dd_info_doc_haut_droite_etage_2_similar,
 #D_dd_info_doc_haut_droite_etage_2_prio_art {
	 font-size:0.8rem;
	cursor: pointer;
	display: flex;
	align-items: center;
 }
 #S_dd_info_doc_haut_droite_etage_2_download_pdf,
 #S_dd_info_doc_haut_droite_etage_2_similarite_label,
 #S_dd_info_doc_haut_droite_etage_2_prior_art_label {
	 font-size: 0.7rem;
 }


#D_dd_info_doc_haut_droite_etage_2_subtype{
	margin-right: 0.5rem;
	font-size: 0.7rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;

}
#D_dd_info_doc_haut_droite_etage_2_source_name{
	margin-left:0;
	font-size: 0.7rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}

#D_dd_info_doc_haut_droite_etage_2_source_issn{
	margin-left: auto;
	padding-right: 0.5rem;
	font-size: 0.7rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}

#D_dd_info_doc_haut_droite_etage_2_source_doi,
#D_dd_info_doc_haut_droite_etage_3_keywords,
#D_dd_info_doc_haut_droite_etage_3_affi,
#D_dd_info_doc_haut_droite_etage_3_auteurs,
#D_dd_info_doc_haut_gauche_abstract,
#D_dd_info_doc_haut_gauche_ext_ressource,
#D_dd_info_doc_haut_gauche_pubdomain,
#D_dd_info_doc_haut_gauche_trial{
	padding: 0.5rem 0 0.5rem 0.5rem;
	border-bottom: solid 1px #e0e0e0;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
}

#D_dd_info_doc_haut_droite_etage_2_source_doi_label,
#D_dd_info_doc_haut_droite_etage_3_keywords_label,
#D_dd_info_doc_haut_droite_etage_3_affi_label,
#D_dd_info_doc_haut_droite_etage_3_auteurs_label,
#D_dd_info_doc_haut_gauche_abstract_label,
#D_dd_info_doc_haut_gauche_ext_ressource_label,
#D_dd_info_doc_haut_gauche_pubdomain_label,
#D_dd_info_doc_haut_gauche_trial_label{
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	margin-bottom:0.5rem;
}

#D_dd_info_doc_haut_gauche_trial_desc,
#S_dd_info_doc_haut_gauche_trial_etat,
#S_dd_info_doc_haut_gauche_trial_phase,
#S_dd_info_doc_haut_gauche_trial_intervention,
#S_dd_info_doc_haut_gauche_trial_condition,
#S_dd_info_doc_haut_gauche_trial_resultat,
#S_dd_info_doc_haut_gauche_trial_choix,
#S_dd_info_doc_haut_gauche_trial_critere,
#S_dd_info_doc_haut_gauche_trial_financement{
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #4f4e4e;
	margin-bottom:0.5rem;
}

#D_dd_info_doc_haut_droite_etage_3_inventeur_label, 
#D_dd_info_doc_haut_droite_etage_3_current_assignee_label, 
#S_dd_info_doc_haut_droite_etage_3_original_assignee_label,
#D_dd_info_doc_haut_droite_etage_4_famille_label,
#D_dd_info_doc_haut_droite_etage_a_famille_label,
#S_dd_info_doc_haut_gauche_classification,
#D_dd_info_doc_haut_gauche_commentaire_etoile_lib,
#D_dd_info_doc_haut_gauche_cp_lib,
#D_dd_info_doc_haut_droite_etage_cited_label,
#D_dd_info_doc_haut_droite_etage_citing_label,
#D_dd_info_doc_haut_gauche_claims_label,
#D_dd_info_doc_haut_gauche_claims_independante_label,
#D_dd_info_doc_haut_gauche_classification_label,
#D_dd_info_doc_haut_gauche_desc_label,
#D_dd_info_doc_haut_gauche_desc_orginale_label,
#D_dd_info_doc_haut_gauche_info_cle_label
{
	 color:black;
	 font-weight: bold;
 }

 #D_dd_info_doc_haut_droite_etage_a_famille_infos {
	position: absolute;
	top:1.2rem;
	right:2.5rem;
	font-size:0.6rem;
	color:#9e9e9e;
 }

 #D_dd_info_doc_haut_droite_etage_3_inventeur_label,
 #D_dd_info_doc_haut_droite_etage_3_current_assignee_label{
	 margin-right: 0.5rem;
 }

 #D_dd_info_doc_haut_droite_etage_3_current_assignee_liste, 
 #D_dd_info_doc_haut_droite_etage_3_inventeur_liste{
	 font-size: 0.7rem;
 }
 
 .S_dd_info_doc_haut_droite_etage_3_inventeur, 
 .S_dd_info_doc_haut_droite_etage_3_current_assignee, 
 .S_dd_info_doc_haut_droite_etage_3_original_assignee,
 .S_dd_info_doc_haut_droite_etage_4_famille{
	 color: #212121;
 }

#D_dd_info_doc_haut_droite_etage_3, 
#D_dd_info_doc_haut_droite_etage_4, 
#D_dd_info_doc_haut_droite_etage_a, 
#D_dd_info_doc_haut_droite_etage_cited_citing_legal,
#D_dd_info_doc_haut_gauche_images,
#D_dd_info_doc_haut_gauche_classification,
#D_dd_info_doc_haut_gauche_claims,
#D_dd_info_doc_haut_gauche_claims_independante,
#D_dd_info_doc_haut_gauche_desc,
#D_dd_info_doc_haut_gauche_desc_orginale,
#D_dd_info_doc_haut_gauche_info_cle,
#D_dd_info_doc_haut_gauche_commentaire,
#D_dd_info_doc_haut_gauche_cp{
	border-bottom: 0.01rem solid #E5E5E5;
	padding: 1rem 0.1rem 0.5rem 0.5rem;
	font-size:0.8rem;
}

.listedoc #D_dd_info_doc_haut_gauche,
.listedoc  #D_dd_info_doc_haut_droite{
	border: 0.01rem solid #E5E5E5;
}

#D_dd_info_doc_haut_droite_etage_3_inventeur, 
#D_dd_info_doc_haut_droite_etage_3_current_assignee, 
#D_dd_info_doc_haut_droite_etage_4_famille{
	display:flex;
	flex-direction: row;
	margin-bottom: 1rem;
}

#D_dd_info_doc_haut_droite_etage_4_famille_label{
	margin-right: 0.5rem;
}

#D_dd_info_doc_haut_droite_etage_4_famille_liste{
	display: flex;
	flex-wrap: wrap;
	color: #212121;
}

.S_dd_info_doc_haut_droite_etage_4_famille{
	display:flex;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}

.I_dd_info_doc_haut_droite_etage_4_famille{
	width:1rem;
	height:1rem;
	margin-right:0.2rem;
	margin-top:0.1rem;
}


.I_v_apl_image{
	width:1rem;
	height:1rem;
}

.I_v_apl_image_dot{
	width:1.2rem;
	height:1.2rem;
}

#D_dd_info_doc_haut_droite_etage_a,
#D_dd_info_doc_haut_droite_etage_cited,
#D_dd_info_doc_haut_droite_etage_citing,
#D_dd_info_doc_haut_droite_etage_legal,
#D_dd_info_doc_haut_gauche_claims,
#D_dd_info_doc_haut_gauche_claims_independante,
#D_dd_info_doc_haut_gauche_classification,
#D_dd_info_doc_haut_gauche_desc,
#D_dd_info_doc_haut_gauche_desc_orginale,
#D_dd_info_doc_haut_gauche_info_cle,
#D_dd_info_doc_haut_gauche_commentaire_etoile{
	position: relative;
}

#D_dd_info_doc_haut_droite_etage_cited{
	margin-bottom: 1rem;
	padding-right:0.2rem;
}

#D_dd_info_doc_haut_droite_etage_citing{
	padding-right:0.2rem;
}


#D_dd_info_doc_haut_droite_etage_a_famille_open,
#D_dd_info_doc_haut_droite_etage_cited_open,
#D_dd_info_doc_haut_droite_etage_citing_open,
#D_dd_info_doc_haut_droite_etage_legal_open,
#D_dd_info_doc_haut_gauche_claims_open,
#D_dd_info_doc_haut_gauche_claims_independante_open,
#D_dd_info_doc_haut_gauche_classification_open,
#D_dd_info_doc_haut_gauche_desc_open,
#D_dd_info_doc_haut_gauche_desc_orginale_open,
#D_dd_info_doc_haut_gauche_info_cle_open,
#D_dd_info_doc_haut_gauche_cp_open{
	position: absolute;
	right: 0.1rem;
	top: 1rem;
	background-repeat:no-repeat;
	background-position: center;
	background-size:contain;
	margin-right:0.3rem;
	width:1rem;
	height: 1rem;
	background-image: url("/images/div_open.svg");
	cursor:pointer;
}

#D_dd_info_doc_haut_droite_etage_a_famille_open.div_close,
#D_dd_info_doc_haut_droite_etage_cited_open.div_close,
#D_dd_info_doc_haut_droite_etage_citing_open.div_close,
#D_dd_info_doc_haut_droite_etage_legal_open.div_close,
#D_dd_info_doc_haut_gauche_claims_open.div_close,
#D_dd_info_doc_haut_gauche_claims_independante_open.div_close,
#D_dd_info_doc_haut_gauche_classification_open.div_close,
#D_dd_info_doc_haut_gauche_desc_open.div_close,
#D_dd_info_doc_haut_gauche_desc_orginale_open.div_close,
#D_dd_info_doc_haut_gauche_info_cle_open.div_close,
#D_dd_info_doc_haut_gauche_commentaire_open.div_close,
#D_dd_info_doc_haut_gauche_cp_open.div_close{
	background-image: url("/images/div_close.svg");
}


.LIST #D_dd_info_doc_haut_droite_etage_a_famille_open.div_open, .WORKFILE #D_dd_info_doc_haut_droite_etage_a_famille_open.div_open,
.LIST #D_dd_info_doc_haut_droite_etage_cited_open.div_open, .WORKFILE #D_dd_info_doc_haut_droite_etage_cited_open.div_open,
.LIST #D_dd_info_doc_haut_droite_etage_citing_open.div_open, .WORKFILE #D_dd_info_doc_haut_droite_etage_citing_open.div_open,
.LIST #D_dd_info_doc_haut_droite_etage_legal_open.div_open, .WORKFILE #D_dd_info_doc_haut_droite_etage_legal_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_claims_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_claims_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_claims_independante_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_claims_independante_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_classification_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_classification_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_desc_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_desc_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_desc_orginale_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_desc_orginale_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_info_cle_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_info_cle_open.div_open,
.LIST #D_dd_info_doc_haut_gauche_commentaire_open.div_open, .WORKFILE #D_dd_info_doc_haut_gauche_commentaire_open.div_open,
.WORKFILE #D_dd_info_doc_haut_gauche_cp_open.div_open{
	background-image: url("/images/div_open_list.svg");
}

.LIST #D_dd_info_doc_haut_droite_etage_a_famille_open.div_close, .WORKFILE #D_dd_info_doc_haut_droite_etage_a_famille_open.div_close,
.LIST #D_dd_info_doc_haut_droite_etage_cited_open.div_close, .WORKFILE #D_dd_info_doc_haut_droite_etage_cited_open.div_close,
.LIST #D_dd_info_doc_haut_droite_etage_citing_open.div_close, .WORKFILE #D_dd_info_doc_haut_droite_etage_citing_open.div_close,
.LIST #D_dd_info_doc_haut_droite_etage_legal_open.div_close, .WORKFILE #D_dd_info_doc_haut_droite_etage_legal_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_claims_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_claims_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_claims_independante_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_claims_independante_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_classification_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_classification_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_desc_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_desc_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_desc_orginale_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_desc_orginale_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_info_cle_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_info_cle_open.div_close,
.LIST #D_dd_info_doc_haut_gauche_commentaire_open.div_close, .WORKFILE #D_dd_info_doc_haut_gauche_commentaire_open.div_close,
.WORKFILE #D_dd_info_doc_haut_gauche_cp_open.div_close{
	background-image: url("/images/div_close_list.svg");
}

.ALERTE #D_dd_info_doc_haut_droite_etage_a_famille_open.div_open,
.ALERTE #D_dd_info_doc_haut_droite_etage_cited_open.div_open,
.ALERTE #D_dd_info_doc_haut_droite_etage_citing_open.div_open,
.ALERTE #D_dd_info_doc_haut_droite_etage_legal_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_claims_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_claims_independante_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_classification_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_desc_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_desc_orginale_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_info_cle_open.div_open,
.ALERTE #D_dd_info_doc_haut_gauche_commentaire_open.div_open{
	background-image: url("/images/div_open_alert.svg");
}

.ALERTE #D_dd_info_doc_haut_droite_etage_a_famille_open.div_close,
.ALERTE #D_dd_info_doc_haut_droite_etage_cited_open.div_close,
.ALERTE #D_dd_info_doc_haut_droite_etage_citing_open.div_close,
.ALERTE #D_dd_info_doc_haut_droite_etage_legal_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_claims_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_claims_independante_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_classification_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_desc_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_desc_orginale_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_info_cle_open.div_close,
.ALERTE #D_dd_info_doc_haut_gauche_commentaire_open.div_close{
	background-image: url("/images/div_close_alert.svg");
}

#D_dd_info_doc_haut_droite_etage_cited_open,
#D_dd_info_doc_haut_droite_etage_citing_open,
#D_dd_info_doc_haut_droite_etage_legal_open{
	top:0;
}
#D_dd_info_doc_haut_droite_etage_citing,
#D_dd_info_doc_haut_droite_etage_legal{
	margin-top:0.5rem;
}


.famille_th_application{
	text-align: left;
}

.famille_th_pn{
    width: 7rem;
    text-align: left;
}

.I_v_apl_image{
	width:1rem;
	height:1rem;
	margin-right: 0.5rem;
}

.I_v_apl_image_pdf{
	width:0.7rem;
	height:0.7rem;
	margin-right: 0.5rem;
    margin-top: 0.3rem;
}

.I_v_apl_image_dot{
	width:1.4rem;
	height:1.4rem;
}

.D_vapl_principal_info{
	display:flex;
    min-height: 1.4rem;
}

.D_vapl_principal_info.head{
	min-height:unset;
	margin-bottom: 0.3rem;
}


.D_vapl_principal_info.color0{
	background: #f5f5f5;
}

.D_vapl_principal_info.color1{
	background: white;
}

.D_v_apl_td_1{
	flex:5;
	display:flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.D_v_apl_td_2{
	flex: 8;
    margin-top: auto;
    margin-bottom: auto;
}

.D_v_apl_td_3{
	flex: 4;
	text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}


.D_v_apl_td_4{
	flex:5;
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


.WORKFILE .D_v_apl_td_4{
	display:none;
}

.D_v_apl_td_5{
	flex:1;
}

.D_v_apl_td_1.head,
.D_v_apl_td_2.head,
.D_v_apl_td_3.head,
.D_v_apl_td_4.head,
.D_v_apl_td_5.head{
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	font-size: 0.7rem;
	color: #9e9e9e;
}

.D_v_apl_td_4 .GRANTED{
	width:6px;
	height:6px;
	background-color: #8491d1;
}

.D_v_apl_td_4 .PENDING{
	width:6px;
	height:6px;
	background-color: #c4c9e8;
}

.D_v_apl_td_4 .LAPSED,
.D_v_apl_td_4 .EXPIRED,
.D_v_apl_td_4 .REVOKED{
	width:6px;
	height:6px;
	background-color: #f44336;
}

.D_v_apl_td_4_label{
	margin-left:0.2rem;
	width: 3.5rem;
	text-align: left;
}

#D_dd_info_doc_milieu{
	display:flex;
	flex-direction: row;
	align-items:top;
	width:100%;
    max-width:100%;
}

#D_dd_info_doc_milieu_gauche{
	flex:1;
	padding-right:1rem;
}

#D_dd_info_doc_bas{
    max-width:100%;
}

#I_dd_info_doc_bas_patent_citation_open{
	max-width: 1rem;
	max-height: 1rem;
	cursor:pointer;
}

#T_dd_info_doc_bas_patent_citation, #T_dd_info_doc_bas_cited_by, #T_dd_info_doc_bas_legal_events{
	width:100%;
    word-break: break-word;
    border-collapse: collapse;
	border:0.1rem solid #e0e0e0;
	font-size:0.7rem;
	margin-top:1rem;
}

#T_dd_info_doc_bas_patent_citation thead, #T_dd_info_doc_bas_cited_by thead,#T_dd_info_doc_bas_legal_events thead{
	background: #fafafa;
	color: #545252;
	font-weight:500;
}

#T_dd_info_doc_bas_patent_citation td, #T_dd_info_doc_bas_patent_citation th,
#T_dd_info_doc_bas_cited_by td, #T_dd_info_doc_bas_cited_by th,
#T_dd_info_doc_bas_legal_events td, #T_dd_info_doc_bas_legal_events th{
	border-bottom:0.1rem solid #e0e0e0;
	padding:0.5rem 1rem 0 0;
	vertical-align:top;
}

#T_dd_info_doc_bas_patent_citation td:nth-child(1), #T_dd_info_doc_bas_legal_events td:nth-child(1){
	min-width:7rem;
}

#T_dd_info_doc_bas_patent_citation td:nth-child(2), #T_dd_info_doc_bas_patent_citation td:nth-child(3),
 #T_dd_info_doc_bas_cited_by td:nth-child(2), #T_dd_info_doc_bas_cited_by td:nth-child(3){
	min-width:5rem;
}

#T_dd_info_doc_bas_patent_citation tfoot td{
	height:5rem;
}

#D_dd_info_doc_bas_patent_citation_encours,
#D_dd_info_doc_bas_patent_cited_encours{
	background-image: url(/images/load.gif);
	background-repeat:no-repeat;
	background-position: center;
	background-size:contain;
	height:2rem;
	width:100%;
}

#D_dd_info_doc_bas_patent_citation{
	padding: 0 0.4rem 0 0.2rem;
}

.D_dd_info_doc_bas_patent_citation_haut,
.D_dd_info_doc_bas_patent_citation_milieu,
.D_dd_info_doc_bas_patent_citation_bas{
	margin-bottom: 0.4rem;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.D_dd_info_doc_bas_patent_citation_haut{
	margin-top:1rem;
	justify-content: space-between;
	align-items: center;
}

.D_dd_info_doc_bas_patent_citation_haut_PN,
.D_dd_info_doc_bas_patent_citation_haut_PD,
.D_dd_info_doc_bas_patent_citation_haut_APP{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.S_dd_info_doc_bas_patent_citation_haut_PN,
.S_dd_info_doc_bas_patent_citation_haut_PD,
.S_dd_info_doc_bas_patent_citation_haut_APP,
.S_dd_info_doc_bas_patent_citation_haut_titre,
.S_dd_info_doc_bas_patent_citation_haut_ass{
	color:#9e9e9e;
	font-size: 0.8em;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	padding-right: 0.5rem;
}

.HR_dd_info_doc_bas_patent_citation_ligne{
	margin-top:1rem;
	color: #e0e0e0;
    opacity: 0.4;
    width: 95%;
}

#D_dd_info_doc_haut_gauche_desc_info,
#D_dd_info_doc_haut_gauche_desc_orginale_info,
#D_dd_info_doc_haut_gauche_info_cle_info,
#D_dd_info_doc_haut_gauche_info_cle_info_2{
	display: inline-block;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	line-break: strict;
	hyphens: none;
	-webkit-hyphens: none;
	-moz-hyphens: none;
}


#D_dd_info_doc_haut_gauche_info_cle_info,
#D_dd_info_doc_haut_gauche_info_cle_info_2{
	margin-top:1rem;
}


@media (min-width: 812px) and (max-width: 1300px) {
	.listedoc #D_dd_info_doc_haut{
		flex-direction: column;
	}

	.listedoc #D_dd_info_doc_picture{
		height: initial;
		max-height: initial;
	}

    .rechercheavancee #D_dd_info_doc_haut{
        flex-direction: column;
    }
}

@media (max-width: 811px) {
    .rechercheavancee #D_dd_info_doc_haut, .listedoc #D_dd_info_doc_haut{
        flex-direction: column;
	}

	.listedoc #D_dd_info_doc_picture{
		height: initial;
		max-height: initial;
	}

     .rechercheavancee #D_droite_document, .listedoc #D_droite_document {
        overflow-y:initial;
		max-height:initial;
	}

	.listedoc #D_dd_info_doc_haut_gauche{
		margin-right: initial;
	}
}

/************************
 CHAMP PERSOS
 ***************************/



 #D_dd_info_doc_haut_gauche_cp_titre{
	display:flex;
	flex-direction: row;
	position: relative;
	margin: 0 0 1rem 0;
}

#D_dd_info_doc_haut_gauche_cp_liste{
	margin: 0 0.5rem 0 0.5rem;
	border-top: solid 1px #e0e0e0;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lst,
.D_dd_info_doc_haut_gauche_cp_liste_un_dat,
.D_dd_info_doc_haut_gauche_cp_liste_un_num,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk,
.D_dd_info_doc_haut_gauche_cp_liste_un_cla{
	display: flex;
	flex-direction: row;
	border-bottom: solid 1px #e0e0e0;
	min-height:2rem;
	max-height: 8rem;
	align-items: center;
	color: #212121;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lst_label,
.D_dd_info_doc_haut_gauche_cp_liste_un_cla_label,
.D_dd_info_doc_haut_gauche_cp_liste_un_dat_label,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_label,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_label{
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	flex:1;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lst_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_cla_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur{
	flex:1.5;
	max-height: 8rem;
	min-height: 2rem;
	cursor:pointer;
}


.D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_valeur,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur{
	display: flex;
	align-items: center;
	max-width: 60%;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur{
	cursor: default;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lst.grise,
.D_dd_info_doc_haut_gauche_cp_liste_un_dat.grise,
.D_dd_info_doc_haut_gauche_cp_liste_un_num.grise,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk.grise,
.D_dd_info_doc_haut_gauche_cp_liste_un_cla.grise,
.D_dd_info_doc_haut_gauche_cp_liste_un_txt.grise{
	color: #6e6e6e;
	pointer-events: none;
}


.D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur:hover,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_valeur:hover,
.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur:hover{
	background-color: #e8eaf6;
}

.grise .D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur:hover{
	background-color: transparent;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur_date{
	border:0;
	cursor: pointer;
	font-size: 0.8rem;
	width:5.5rem;
    background-color: transparent;
}

.D_avancee_date_debut_CP_affichage,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage{
	width:100%;
	display:flex;
	flex-direction: row;
}

.D_avancee_date_debut_CP_affichage_label,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_label{
	padding-left: 1rem;
	background-image: url(/images/date.svg);
	background-repeat:no-repeat;
	background-size: 0.8rem 0.8rem;
	background-position: 0% 10%;
	font-size: 0.7rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_label{
	background-image: none;
	padding-left: 0;
}

.D_avancee_date_debut_CP_affichage_label:hover,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_label:hover{
	text-decoration: underline;
}

.D_avancee_date_debut_CP_affichage_croix,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_croix{
	margin:0 0 0 0.5rem;
	background-image: url(/images/close.svg);
	background-repeat:no-repeat;
	background-size: 0.5rem 0.5rem;
	background-position: center;
	width:0.9rem;
	height:0.9rem;
	display:none;
}

.D_avancee_date_debut_CP_affichage:hover .D_avancee_date_debut_CP_affichage_croix,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage:hover .D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_croix{
	display:initial;
}

.D_avancee_date_debut_CP_affichage_croix:hover,
.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_croix:hover{
	border-radius: 50%;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #ffffff;
	width:0.9rem;
	height:0.9rem;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage{
	width:100%;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage{
	width:100%;
	position: relative;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_label a{
	max-width: calc(100% - 2rem);
	margin-top: 0;
    text-overflow: ellipsis;
    overflow: hidden;
	white-space: nowrap;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3c80f6;
	text-decoration: none;
	cursor:pointer;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_label a:hover{
	text-decoration: underline;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_edit{
    position: absolute;
    right: 0;
    top: -0.5rem;
    background-image: url(/images/cp_texte_display.svg);
    background-repeat: no-repeat;
    background-size: 1.7rem;
    background-position: center;
    width: 2rem;
    height: 2rem;
	display: none;
	cursor:pointer;
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_edit:hover{
    background-image: url(/images/cp_texte_display_hover.svg);
}

.D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage:hover .D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_edit{
	display: initial;
}

#D_dd_info_doc_haut_gauche_cp_manage{
	position: absolute;
	right:2.5rem;
	top:0;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	cursor:pointer;
}

#D_dd_info_doc_haut_gauche_cp_manage:hover{
	text-decoration: underline;
}

#D_dd_info_doc_haut_gauche_cp_nb{
	margin-left:0.5rem;
}

.D_dd_info_doc_haut_gauche_cp_info{
	display:flex;
	flex-direction: row;
	margin:0.5rem 0 0.5rem 0;
}

.S_dd_info_doc_haut_gauche_cp_titre{
	display:inline-block;
	margin-right:0.5rem;
	color:#212121;
	font-weight: bold;
}

.S_dd_info_doc_haut_gauche_cp_valeur{
	font-size:0.8rem;
	display:inline-block;
	color: #212121;

}

.D_dd_info_doc_haut_gauche_cp_valeur_add{
	display:inline-block;
    min-width: 0.6rem;
	height:0.8rem;
	background-image: url(/images/add.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center;
	background-color: white;
	cursor:pointer;
}

#D_dd_info_doc_haut_gauche_cp_open{
    right: 0;
	 top: 0;
}


.D_dd_info_doc_haut_gauche_cp_liste_un_txt{
	border-bottom: solid 1px #e0e0e0;
}

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_label{
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	float:left;
	margin-top:0.5rem;
	max-width:calc( 100% - 5rem);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
 }


 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_titre{
	 height:1.3rem;
	 text-align: center;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_options{
	background-image: url(/images/cp_texte_options.svg);
	background-repeat:no-repeat;
	background-size: 0.6rem 0.75rem;
	background-position: left;
	background-color: white;
	cursor:pointer;
	width:1.5rem;
	height: 2rem;
	float:right;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_options:hover{
	background-image: url(/images/cp_texte_options_hover.svg);
	background-size: 1.7rem 1.7rem;
	width: 2.1rem;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_save{
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	float:right;
	margin-top:0.5rem;
	cursor: pointer;
	border-left: 1px solid #e0e0e0;
	padding-left: 0.8rem;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_save:hover{
	 text-decoration: underline;
 }


 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_display{
	background-image: url(/images/cp_texte_display.svg);
	background-repeat:no-repeat;
	background-size: 1.7rem 1.7rem;
	background-position: center;
	background-color: white;
	cursor:pointer;
	width:2rem;
	height: 2rem;
	float:right;
	display:none;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt:hover  .D_dd_info_doc_haut_gauche_cp_liste_un_txt_display{
	 display:initial;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_display:hover{
	background-image: url(/images/cp_texte_display_hover.svg);
	background-size: 1.7rem 1.7rem;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_valeur{
	margin: 0.5rem 0 0.5rem 0;
 }

 .D_dd_info_doc_haut_gauche_cp_liste_un_txt_valeur .RichComment{
	 border:none;
	 margin-top:0;
	 padding-top: 0;
 }
