.B_comment_action{
    text-align   : center;
    border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
    border       : 0.1rem solid #9e9e9e;
    color        : #3949ab;
    width        : 1rem;
    height       : 1rem;
    height       : 21px;
    width        : 21px;
    margin       : 4px 0 0 0;
}

.B_comment_action:hover{
    border-color: #3949ab;
    background-color: #3949ab;
    color:white;
}

.SM_comment_action > div{
    padding:0;
    margin:0;
    background-color: transparent;
    font-size: 1em;
}

.I_menu{
    max-width: 1em;
    max-height:  1em;
}


.rc-menu-horizontal{
	background-color: transparent;
}

.rc-menu-item, .rc-menu-submenu{
    cursor:pointer;
}


.D_dd_info_doc_haut_gauche_commentaire_un_edit .rc-menu-submenu-active > .rc-menu-submenu-title{
    cursor:pointer;
    background-color:transparent; 
}

.rc-menu-submenu-popup{
    z-index: 99;
}

.rc-menu-item.rc-menu-item-disabled{
    cursor: not-allowed;
}

.hide{
    display:none;
}
