body .ant-popover-inner{
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}


.PophoverOrbit_section{
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
    margin-bottom: 0.4rem;
}

.PophoverOrbit_select_value_un{
    position: relative;
    padding: 0.3rem 0 0.3rem 0;
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    cursor:pointer;
    padding-left: 1.5rem;
	background-repeat:no-repeat;
	background-size: 0.85rem;
    background-position: 0 center;
	background-image: url(/images/select_multiple.svg);
}

.LIST .PophoverOrbit_select_value_un{
    background-image: url(/images/select_multiple_list.svg);
}

.PophoverOrbit_select_value_un.selected{
	background-image: url(/images/select_multiple_check.svg);
}

.LIST .PophoverOrbit_select_value_un.selected{
	background-image: url(/images/select_multiple_check_list.svg);
}

.PophoverOrbit_select_value_un.defaultColor{
    background-image: url(/images/select_multiple_disable.svg);
}

.PophoverOrbit_select_value_un.defaultColor.selected{
	background-image: url(/images/select_multiple_check_disable.svg);
}

.PophoverOrbit_select_value_un_label{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
}

.PophoverOrbit_select_value_un_info{  
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
}

.popHoverSection{
    cursor:initial;  
    border-top: solid 1px #e0e0e0;
    padding-top:0.7rem;
}

.popHoverSection:first-child{
    border-top: none;
    padding-top:0;
}

.popHoverSection:hover{
    background-color: transparent;
}

.popHoverSection.disabled div{
    pointer-events: none;
    cursor: initial;
    color: #9e9e9e;

}

.popHoverSection.disabled .PophoverOrbit_select_value_un{
	background-image: url(/images/select_multiple_disable.svg);
}

.PophoverOrbitlink{
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #3c80f6;
    cursor:pointer;
}

.PophoverOrbitlink.image{
	background-repeat:no-repeat;
	background-size: 0.7rem;
    background-position: 0.2rem center;
    padding-left: 0.9rem;
    height: 1rem;
    background-image: url(/images/plus-circle-outline.svg);
}

.PophoverOrbitlink.image:hover{
    background-image: url(/images/plus-circle.svg);
    
}

.PophoverOrbit_section_bas{
    height: 1rem;  
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c80f6;
    margin-top: 0.25rem;
}

.PophoverOrbit_section_bas_select{
    float:left;
    margin: 0.25rem 0.5rem 0.25rem 0;
    cursor:pointer;
}

.PophoverOrbit_section_bas_deselect{
    float:right;
    margin: 0.25rem 0.25rem 0.25rem 0.5rem;
    cursor:pointer;
}

.lds-ripple-container {
    transform: scale(0.25);
    margin: -30px 0 -30px 0;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid black;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  
