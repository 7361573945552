.RichComment{
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.RichComment #I_Dialog_lib{
    width:97%;
    border-radius: 5px;
}

.RichComment #I_Dialog_comment{
    width:100%;
    height:10rem;
}
.wrapperClassName .rdw-editor-toolbar{
    border:0;
}

.wrapperClassName .rdw-dropdown-wrapper{
    height:25px;
    margin:0;
}

.wrapperClassName .rdw-option-wrapper{
    margin:0;
    min-width: 15px;
    height: 15px;
}

.RichComment .public-DraftStyleDefault-block{
    margin:auto;
}

.RichComment .public-DraftEditor-content{
    margin-top:0.5rem;
}

.editorClassName{
    border: 1px solid grey;
    border-radius: 5px;
    padding-left:1rem;
    padding-bottom: 0.5rem;
    line-height: 1rem;
}

.RichComment .D_infos{
    margin-top: 1rem;
}

.RichComment .S_titre{
    color: black;
    font-weight: bold;
}


.RichComment .S_lib{
    color:#3949ab;
}

.RichComment .I_lib{
    margin-top:0.5rem;
    font-size: 0.8rem;;
}

.RichComment .S_lib_error{
    color:red;
}

.RichComment .rc-dialog-title{
    color:#3949ab;
}

.RichComment .rc-dialog-header{
    background-color: #fafafa;
}

.RichComment .rc-dialog-close{
    top: 0.5rem;
}

.RichComment .rc-dialog-close-x{
    color:#3949ab;
    font-weight: 100;
    font-size: 2rem;
}

.RichComment .D_buttons{
    display:flex;
    flex-direction: row;
    margin-top:0rem;
}

.RichComment .B_valider{
    background-color: #3949ab;
    border-radius: 3px;
    color:white;
    font-size: 0.9rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
}

.RichComment .B_cancel{
    color:#3949ab;
    font-size: 0.9rem;
    margin-right: 2rem;
}

