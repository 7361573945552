.SM_treebeard_menu > div{
    padding:0;
    margin:0;
    background-color: transparent;
}

#D_gestion_user .SM_treebeard_menu{
    background-color: transparent;
}

.I_menu{
    max-width: 1rem;
    max-height:  1rem;
}

.hidden{
    display: none;
}
.rc-menu-item{
    cursor:pointer;
}

.TreebeardMenuIcon{
    width:1rem;
    height:1rem;
    margin-right:0.5rem;
}

#D_account_menu{
	width:2.3rem;
	height:2.3rem;
    background-image: url(/images/user-icon.svg);
	background-repeat:no-repeat;
	background-position: center; 
	background-size: 90%;
    background-color: white;
    background-color: transparent;
}

#D_account_menu:hover{
    background-color: white;
    background-color: transparent;
}

#D_gestion_user > .rc-menu{
    border:none;
    background-color: white;
    background-color: transparent;
}