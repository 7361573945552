#D_recherche_avancee {
	padding       :0.1rem 0.5rem 0 0.5rem;
	font-weight   : normal;
	font-style    : normal;
	font-stretch  : normal;
	line-height   : normal;
	letter-spacing: normal;
	color         : #9e9e9e;
	font-size     : 0.70rem;
	overflow-y    : auto;
	width         : 100%; 
	overflow-x    : hidden; 
}

#D_recherche_avancee:focus{
	outline: none;
}

#D_recherche_avancee_simple, #D_recherche_avancee_semantique{
	padding-top:0.5rem;
}

#D_recherche_avancee_semantique{
	max-height: calc(100% - 5rem);
    overflow-y: auto;
}

.listedoc #D_recherche_avancee{
	display:none
}


.rechercheplus #D_recherche_avancee {
	flex:1;
}


.rechercheplus #D_recherche_avancee_simple{
	display:inline;
}

.rechercheavancee #D_recherche_avancee_simple{
	height: calc(100% - 4.5rem);
}

.rechercheplus #D_recherche_avancee_simple_scope_search_history{
	height:calc(100% - 6rem);
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	/* a supprimer apres*/
	width:95.1%;
	margin:auto;
}

.rechercheavancee #D_recherche_avancee_simple_scope_search_history{
	height:100%;
}


.rechercheplus #D_recherche_avancee_simple_scope_search{
	display:flex;
	flex-direction: row;
	flex:5;
	margin-right:1.5rem;
	border-radius: 2px;
	padding-top: 1rem;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	position: relative;
}

.rechercheavancee #D_recherche_avancee_simple_scope_search{
	height:100%
}

#D_recherche_avancee_simple_scope{
	display:none;
}

.rechercheplus #D_recherche_avancee_simple_scope{
	flex:1.1;
	padding: 0 1rem 0 1rem;
	border-right: solid 1px #eeeeee;
	display:flex;
	flex-direction: column;
}


.rechercheplus #D_recherche_avancee_simple_scope_label{
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #5a95aa;
	margin: 0.5rem 0 0.5rem 0;
}

#D_recherche_avancee_simple_scope_infos, .D_history_elem_un_corps {
	font-size:1.1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #212121;
}

.F_scope_gris{
	font-size: 0.75rem;  
	color: #9e9e9e;
}

.F_scope_reduit{
	font-size: 0.8rem;
	font-weight: 300;
	color: #212121;
}

.F_scope_bleu{
	color: #5a95aa;
}

.F_scope_red{
	color: #de3838;
}


#D_recherche_avancee_simple_search{
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #eeeeee;
}

.rechercheplus #D_recherche_avancee_simple_search{
	padding-left: 1rem;
	flex:3;
	border-bottom: none;
}

.rechercheavancee #D_recherche_avancee_simple_search{
	height: 100%;
}

.rechercheplus #D_recherche_avancee_simple_search_criteres{
	overflow-y: auto;
	max-height: calc(100% - 6rem);
}

#D_recherche_avancee_simple_history{
	display:none;
}

.rechercheplus #D_recherche_avancee_simple_history{
	display:initial;
	flex:1.2;
	padding-top: 1rem;
	border-radius: 2px;
	padding: 1rem;;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.rechercheplus #D_recherche_avancee_simple_history_label{	
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #5a95aa;
	margin: 0.5rem 0 0.6rem 0;
}

#D_recherche_avancee_simple_history_aide{
	width:89%;
	height:calc(100% + 1.5rem);
	position: absolute;
	top:-1rem;
	right:-2rem;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #ffffff;
	z-index:9999;
	display:none;
}

#D_recherche_avancee_simple_history_aide_entete{
	width:100%;
	text-align: center;
	position: relative;
}

#D_recherche_avancee_simple_history_aide_titre{
	font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212121;
  margin-top: 1rem;
}

#D_recherche_avancee_simple_history_aide_date{
	position: absolute;
	right:1rem;
	top: 0.2rem;
	font-size: 0.75rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #212121;
}

#D_recherche_avancee_simple_history_aide_corps{
	display: flex;
	flex-direction: row;
	height: calc( 100% - 3rem);
	margin-top: 1rem;
}

#D_recherche_avancee_simple_history_aide_corps_scope{
	border-right: solid 1px #eeeeee;
	flex:1;
	padding: 0.5rem 1rem 0 1rem;
}

#D_recherche_avancee_simple_history_aide_corps_scope_info{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #212121;
}

#D_recherche_avancee_simple_history_aide_corps_scope_label{
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #5a95aa;
	margin-bottom: 1rem;
}

#D_recherche_avancee_simple_history_aide_corps_elements{
	flex:2;
    overflow-y: auto;
    overflow-x: hidden;
}

.D_history_aide_input{
	display: inline-block;
    width: calc(100% - 3rem);
	font-size:1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	border: solid 1px #bdbdbd;
	padding:0.5rem;
	margin:0.6rem 1rem 0.6rem 1rem;
}

.D_history_aide_input .F_scope_reduit{
	margin:0 0.5rem 0 0.5rem;
	font-size:0.9rem;  
	color: #9e9e9e;
}

.D_history_aide_and{
	margin-left:1rem;
}

.D_history_aide_input_exclude{
	width:100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 1rem 0 1rem 0;
}

.D_history_aide_input_exclude_label{
	margin: 0 1rem 0 1rem;
	font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
    font-size: 0.9rem;
}

.D_history_aide_input_exclude_trait{
	flex: 1 1;
    border-bottom: 1px solid #eeeeee;
    margin: 0 1rem 0 0;
}


#D_recherche_avancee_simple_history_liste{
	height: calc(100% - 2.6rem);
	max-height: calc(100% - 2.6rem);
	overflow-y: auto;
	margin: 0 0.5rem 0.5rem 0.5rem;
}

.D_history_elem_un{
	border-top: 1px solid #eeeeee;
	padding: 0.8rem 0 0.5rem 0;
}

.D_history_elem_un_corps{
	min-height: 2rem;
	position: relative;
}

.D_history_elem_un_corps_action{
	position: absolute;
	display:none;
	flex-direction: row;
	flex-wrap: wrap;
    width: 100%;
    height: 100%;
	justify-content: center;
	opacity: 1;
}

.D_history_elem_un:hover .D_history_elem_un_corps_action{
	display:flex;
}

/*.D_history_elem_un:hover .D_history_elem_un_corps{
	opacity: 0.6;
}*/

.D_history_elem_un_entete{
	display:list-item;
	font-size:0.9rem;
	margin-bottom: 0.3rem;
}

.D_history_elem_un:hover .D_history_elem_un_entete_gauche{
	font-weight: bold;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	font-size:0.9rem;
}

.D_history_elem_un:hover .D_history_elem_un_entete_droite{
	color: #212121;  
	font-style: oblique;
}

.D_history_elem_un_entete_gauche{
	float:left;
}

.D_history_elem_un_entete_droite{
	float:right;
}

/*.D_history_elem_un_corps{
	margin-right:1rem;
	color:#444;
	word-break: break-word;
	hyphens: auto;
	-webkit-hyphens: auto; 
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-ms-word-break: break-all;
	word-wrap: break-all; 
}


.D_history_elem_un_corps > p{
	font-size:0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	overflow: hidden;
	position: relative;
	line-height: 0.9rem;
	max-height: 4.4rem;
	text-align: justify;
	margin: 0 -1rem 0 0;
	padding-right: 1rem;
}

.D_history_elem_un_corps>p:before{
	content: '...';
	position: absolute;
	right: 0;
	bottom: 0;
}

.D_history_elem_un_corps>p:after{
	content: '';
	position: absolute;
	right: 0;
	width: 1rem;
	height: 1rem;
	margin-top: 0.2rem;
	background: white;
}*/

.D_history_elem_un_corps>p{
display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  max-height: 3.3rem; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.1rem;
  -webkit-line-clamp:3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.D_history_elem_un_corps_action_view, .D_history_elem_un_corps_action_edit{
	margin:auto;
	border-radius: 3px;
	border: solid 1px #5a95aa;
	background-color: #ffffff;
	padding: 0.2rem 0.5rem 0.2rem 0.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #5a95aa;
	cursor:pointer;
}

.D_history_elem_un_corps_action_view:hover, .D_history_elem_un_corps_action_edit:hover{
	color:white;
	background-color:#5a95aa;
    box-shadow: 0 0 4px 0 #5a95aa;
}


#D_recherche_avancee_simple_entete{
	display:none;
}

.rechercheplus #D_recherche_avancee_simple_entete{
	display   : inline-block;
	width     : 50%;
	left      : 25%;
	height    : 5rem;
	text-align: center;
	position  : relative;
}

#S_recherche_avancee_simple{
	font-size:0.8rem;
	display:block;
	margin: 0.5rem 0 1rem 0;
}

.rechercheplus #D_recherche_avancee_simple_search_similarite{	
    width: calc(100% - 3rem);
    max-width: calc(100% - 3rem);
}

#D_recherche_avancee_simple_search_similarite_cadre{
	padding:0.3rem 0.5rem 0.3rem 0.5rem;
	font-size: 0.75rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #757575;
	border: solid 1px #e0e0e0;
	background-color: #eeeeee;
}

#D_recherche_avancee_simple_search_similarite_liste{
	margin-top:0.5rem;
	font-size: 0.75rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
}

#S_recherche_avancee_simple_fields{
    margin-top: 0.5rem;
    display: inline-block;
}

#D_search_terme_NPL_not_available_label{
	margin-bottom: 0.5rem;
	padding-left: 1.5rem;
	background-image: url(/images/information-outline.svg);
	background-repeat:no-repeat;
	background-position: left top 0.3rem;
	background-size:1.1rem;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	color: #9e9e9e;
}

.rechercheplus #S_recherche_avancee_simple_entete_adv{
	display: inline-block;
	margin-top: 1rem;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #5a95aa;
}

.rechercheplus #S_recherche_avancee_simple_entete_easy{
	position: absolute;
	right:0.5rem;
	font-size:0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	cursor:pointer;

}

.rechercheplus #S_recherche_avancee_simple_entete_semantic{
	position: absolute;
	right:0.5rem;
	top:1rem;
	font-size:0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	cursor:pointer;
}

#T_search_semanique{
	margin: 1rem 0.5rem 0 0.5rem;
	width:calc(100% - 2rem);
	resize: vertical; 
	min-height:20rem;
    font-size: 1rem;
}

.I_avancee{
	width             :150px;
	min-width         : 2rem;
	max-width         : 100%;
	color             : black;
	min-height        : 1.2rem;
	height            : 24px;
	font-family       : 'roboto';
	font-size         : 0.8rem;
    font-weight       : normal;
    font-style        : normal;
    font-stretch      : normal;
    line-height       : normal;
    letter-spacing    : normal;
	overflow          : hidden;
	resize            : none;
	border            :none;
    box-shadow        : none !important;
	-webkit-box-shadow: none;
    -moz-box-shadow   : none;
	outline           :0px !important;
	-webkit-appearance:none;
	line-height       : 24px;
	padding           : 0 0 0 5px; 
}

.D_avancee_field.inactif .I_avancee{
	color:#9e9e9e;
	background-color: #eeeeee;
}

.D_avancee_field.inactif .I_avancee::placeholder{
	color:#9e9e9e;
	line-height: 22px;
	font-style: oblique;
}

.D_avancee_field.inactif .D_avancee_croix{
	display:none;
}

#S_mesure_texte{
    position: absolute;
	opacity:0;
	overflow:auto;
	font-size: 0.8rem;
	font-family: 'roboto';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
	letter-spacing: normal;
}

.I_avancee::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	letter-spacing: normal;
	line-height: 24px;
}


.I_avancee_d_infos{
	display:flex;
	align-items: center;
	border-bottom: 1px solid transparent;
	/*max-width: calc(100% - 3rem);*/
	margin-right:0.5rem;
}

.rechercheplus .I_avancee_d_infos{
	max-width: 95%;
}

.I_avancee_d_infos.rempli:hover{
	border-bottom: 1px solid black;
}

.I_avancee_d_infos:focus-within, .I_avancee_d_infos.rempli:focus-within{
	border-bottom-color: transparent;
}

.I_avancee_d {
	min-height:1.2rem;
	display:flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	max-width: 100%;
	margin-top: auto;
	margin-bottom: auto;
}


#IT_avancee_termes{
	width:100%;
}

.D_avancee_and, .D_avancee_field{
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;
}

.D_avancee_field{
	margin: 0.6rem 0 0.6rem 0;
}

.D_avancee_field.inactif{
	pointer-events: none;
	background-color: #eeeeee;
}

.D_avancee_termes{
	width:100%;
	max-width: 100%;
	border: 0.01rem solid #e0e0e0;
	box-sizing: border-box;
	font-size:1rem;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	flex-wrap: wrap;
}

.D_avancee_termes:hover {
	border: 0.01rem solid #C9C9C9;
}

.rechercheplus .D_avancee_termes{
	width:calc(100% - 3rem);
	max-width: calc(100% - 3rem);
	padding-bottom: 0.01rem;
}

.rechercheplus #D_avancee_date{
	display:flex;
	flex-direction: row;
}

#D_avancee_date_dates .react-datepicker__close-icon::after{
	background-color:transparent;
	background-image: url(/images/close.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:0.5rem;
	content:'';
}


.D_avancee_and_param{
    margin-top: 0.6rem;
    margin-left: 0.5rem;
}

.D_avancee_and_poubelle{
	background-image: url(/images/trash.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:contain;
	width:1.2rem;
	height:1.2rem;
	margin-left: 0.5rem;
	margin-top: 0.7rem;
	cursor:pointer;
}

#D_search_terme_add_exclude, #D_search_terme_add_only, #D_search_terme_exclude_only{
	width:100%;
	border-bottom: 1px solid #eeeeee;
	height:1.2rem;
	padding-top: 0.3rem;
	margin-bottom: 11px;
    padding-bottom: 0.5rem;
    height: 1.8rem;
}

.rechercheplus #D_search_terme_add_exclude, .rechercheplus #D_search_terme_add_only,.rechercheplus #D_search_terme_exclude_only{
	width:calc(100% - 3rem);
}

#D_search_terme_add_only{
	display:table;
	border-bottom: initial;
}

#D_search_terme_add_only_exclude{
	display: flex;
	flex-direction: row;
	align-items: baseline;
	margin:1rem 0 1rem 0;
}

#D_search_terme_add_only_exclude_trait{
	flex:1;
	border-bottom: 1px solid #eeeeee;
	margin: 0 0 0 1rem;
}


#S_search_terme_add,#S_search_terme_exclude, #S_search_terme_exclude_only_exclude, #S_search_terme_add_only_add {
	float:right;
	margin-right: 0.5rem;
	font-size: 0.70rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3c80f6;
	cursor: pointer;
}

#S_search_terme_add_exclude_barre{
	margin: -0.05rem 0.5rem 0 0;
	float: right;
	height: 0.8rem;
	font-size: 0.8rem;
	color:#e0e0e0;
	border:none;
	
}

.S_avancee_or{
	display:block;
	margin:0 0.5rem 0 0.5rem;
	font-size:0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #9e9e9e;
	margin-top: -0.1rem;
}

.D_avancee_croix{
	display:block;
	height:0.5rem;
	width:0.5rem;
	color:black;
	cursor:pointer;
	background-image: url(/images/close.svg);
	background-repeat:no-repeat;
	background-position: bottom;
	background-size:contain;
}

.rechercheplus .D_avancee_croix{
	height:0.5rem;
	width:0.5rem;
}


#D_avancee_date_select {
	padding-left       : 24px;
	background-image   : url(/images/baseline-today-24-px.svg);
	background-repeat  : no-repeat;
	background-position: 7px 6px;
}

#D_avancee_date_select .ant-select {
	line-height: 30px;
}

#D_avancee_date_priorite{
	display:flex;
	align-items:center;	
	margin-bottom:1rem;
}

.rechercheplus #D_avancee_date_priorite{
	margin:0;
}

.ant-calendar-picker-icon{
    display: none !important;
}

.ant-calendar-picker-input.ant-input{
	border:0;
	padding:0;  
	font-size:0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color:#212121;
}

.ant-calendar-picker-clear{
	right:3px;
}

.ID_recheche_avancee_date{
	width:5rem;
}

.ID_recheche_avancee_date, #S_avancee_date_tiret{  
	font-weight: normal;
	font-style: oblique;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #9e9e9e;
}

#SO_avancee_langue, #SO_avancee_statuts, #SO_avancee_state{
	margin : 0 0.5rem 0 0.5rem;
	padding: 0.4rem 0 0.4rem 0;  
	border: solid 0.01rem #e0e0e0;
	background-color: #ffffff;
	width:5rem;
	cursor: pointer;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #9e9e9e;
	font-size:0.8rem;

}

.SO_avancee_patent_office{
	margin : 0;
	width:100%;
	/*font-size:1.1em;*/
}

.SO_avancee_patent_office .ant-select-arrow {
	top: 19px !important;
}


.SO_avancee_type_doc{
	margin : 0;
	width:100%;
}

.SO_avancee_type_doc .ant-select-arrow {
	top: 19px !important;
}

#S_avancee_date_select_npl{
	margin : 0 0.5rem 0 0.5rem;
	background-color: #ffffff;
	/*width:5rem;*/
	cursor: pointer;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	font-size:0.8rem;
}

#SO_avancee_date{
	margin : 0 0.5rem 0 0.5rem;
	padding: 0.4rem 0 0.4rem 0;  
	border: none;
	background-color: #ffffff;
	/*width:5rem;*/
	cursor: pointer;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	font-size:0.7rem;
}



#ID_avancee_date_debut, #ID_avancee_date_fin{
	border:0;
	cursor: pointer;
	font-size: 0.8rem;
	width:5.5rem;
}

.rechercheplus #ID_avancee_date_debut, .rechercheplus #ID_avancee_date_fin{
	font-size: 0.9rem;
	width:6.2rem;
}


#ID_avancee_date_debut::placeholder{
	color: #212121;
	font-style: oblique;
}

#ID_avancee_date_fin::placeholder{
	color: #212121;
	font-style: oblique;
}

.D_avancee_global_param{
	margin-top: 1.2rem;
	margin-left:0.6rem;
}

.D_avancee_field .D_avancee_global_param{
	margin-top: 0.6rem;
}

#D_avancee_date_dates{
	display:flex;
	align-items: baseline;
	flex-direction: row;
	flex-wrap: wrap;
	max-width:99%;
}

.rechercheavancee #D_avancee_date_dates{
	margin-left:5px;
}

#D_avancee_date_debut, #D_avancee_date_fin{
	display:flex;
	flex-direction: column;
}

.rechercheplus #D_avancee_date_debut,.rechercheplus #D_avancee_date_fin{
	flex-direction: row;
	align-items: baseline;
}

#S_avancee_date_tiret{
	margin: 0 1rem 0 0;
	border-bottom: 1px solid #9e9e9e;
	width: 0.7rem;
	height:1rem;
	transform: translateY(-25%);
}


#S_avancee_date_avant{
	font-size: 0.6rem;
}

.rechercheplus #S_avancee_date_avant{
	margin: 0 0.5rem 0 0.5rem;
}

#S_avancee_date_apres{
	font-size: 0.6rem;
}

.rechercheplus #S_avancee_date_apres{
	margin: 0 0.5rem 0 0.5rem;
}

#D_avancee_action{
	width:100%;
	display: table;
}

.rechercheplus #D_avancee_action{
	width:calc(100% - 3rem);
}

#D_avancee_valider{
    display: block;
    margin: 2rem auto 1rem auto;
    border-radius: 4px;
	background-color: #5a95aa;
	color:white;
	font-size: 1.4rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	width:10.5rem;
	text-align: center;
	padding: 0.3rem 0 0.3rem 0;
	cursor: pointer;
}

#D_avancee_valider:hover{
    box-shadow: 0 0 4px 0 #5a95aa;
}

#I_avancee_valider{
	height: 1rem;
	margin-right:0.7rem;
}

#D_recherche_avancee_tab{
	position: absolute;
	text-align: left;
	margin-top:1rem;
	background-color: aqua;
}

.rechercheplus #D_recherche_avancee_tab{
	display:none;
}

#S_search_terms, #B_avancee_cancel, #B_avancee_semantique_cancel{
	margin-right: 1rem;
}


#S_search_terms, #S_search_terms_adv, .search-mode-link{
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	font-size: 0.6rem;
	cursor: pointer;
}

#B_avancee_cancel{
	cursor:pointer;
	font-size: 0.7rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3c80f6;
}

#S_search_semantique{
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #3c80f6;
	font-size: 0.6rem;
	cursor: pointer;
}

#S_search_terms.selected{
	display:none;
}

#S_search_semantique.selected{
	display:none;
}

.D_avancee_morefields{
	float:right;
	margin-top: 0.08rem;
}

.rechercheplus .D_avancee_morefields{
	margin-top: 0rem;
}

/*.D_avancee_termes .SelectCustom{
	min-width:4rem;
}

.D_avancee_termes .SelectCustom:nth-child(2){
	min-width:4.5rem;
}*/

@media (min-width: 812px) and (max-width: 1380px){
	#D_recherche_avancee {
		flex:1;
	}

	#S_avancee_date_tiret{
		width:0.4rem;
		margin: 0 0.2rem 0 0;
	}

	#D_avancee_date_debut, 
	#D_avancee_date_fin,
	#ID_avancee_date_debut,
	#ID_avancee_date_fin{
		font-size: 0.7rem;
		width:4.4rem;
	}

	.rechercheplus #D_avancee_date_debut, 
	.rechercheplus #D_avancee_date_fin,
	.rechercheplus #ID_avancee_date_debut,
	.rechercheplus #ID_avancee_date_fin{
		width:7.5rem;
	}

	#D_avancee_date_fin .react-datepicker__close-icon{
		padding: 0;
	}

	#D_avancee_date_fin .react-datepicker__close-icon::after{
		padding: 0;
		height: 0.7rem;
		width:0.7rem;
	}

}

@media (max-width: 811px){
	#D_recherche_avancee {
		flex:1.5;
	}

	#S_avancee_date_tiret{
		width:0.4rem;
		margin: 0 0.2rem 0 0;
	}

	#D_avancee_date_debut, 
	#D_avancee_date_fin,
	#ID_avancee_date_debut,
	#ID_avancee_date_fin{
		font-size: 0.7rem;
		width:4.4rem;
	}

	.rechercheplus #D_avancee_date_debut, 
	.rechercheplus #D_avancee_date_fin,
	.rechercheplus #ID_avancee_date_debut,
	.rechercheplus #ID_avancee_date_fin{
		width:7rem;
	}
}