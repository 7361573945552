.D_CP_Manage .rc-dialog-content{
    background-color: transparent;
}

.D_CP_Manage .rc-dialog-close{
    display:none;
}

#D_CP_Manage{
    width:50rem;
    background-color: white;
    max-height: 500px;
    height:450px;
}

#D_CP_Manage_Header{
    height: 3.12rem;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #bdbdbd;
    background-color: #fafafa;
    position: relative;
}

#D_CP_Manage_Header_Label{
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3949ab;
    padding: 0.8rem 0 0 1rem;
}

#D_CP_Manage_Header_croix{
   position: absolute;
   right: 0.5rem;
   top: 1rem;
   background-image: url(/images/close.svg);
	background-repeat:no-repeat;
	background-size: 1rem 1rem;
    background-position: center; 
    cursor:pointer;
    width:1rem;
    height:1rem;
}

#D_CP_Manage_NB_Fields{
    padding: 1.4rem 0 0 0.5rem;
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
    border-bottom: solid 1px #e0e0e0;
}

#D_CP_Manage_centre{
    display: flex;
    flex-direction: row;
    max-height: calc(100% - 8.4rem);
    height: calc(100% - 8.4rem);
}

#D_CP_Manage_centre_gauche{
    flex:2;
    max-width: 40%;
    max-height: 100%;
}

#D_CP_Manage_centre_gauche_liste{
    max-height: calc(100% - 1.5rem);
    overflow-y: auto;
}



#D_CP_Manage_centre_gauche_add_cp{  
    margin: 0.5rem 0 0 0.5rem;
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c80f6;
    cursor:pointer;
}

#D_CP_Manage_centre_gauche_add_cp:hover{ 
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c80f6;
    cursor:pointer;
    background-color: white;  
    border-bottom: solid 1px #3c80f6;
}

#D_CP_Manage .SM_CPAddMenu{
    background-color: transparent;
}

#D_CP_Manage .rc-menu-submenu-title{
    background-color: transparent;
}

#D_CP_Manage .rc-menu-item.rc-menu-item-active{
background-color: transparent;
}

#D_CP_Manage_centre_droite{
    flex:4;
    border-left: solid 5px #e0e0e0;
    max-height: 100%;
}

#D_CP_Manage_centre_droite_haut{
    display: flex;
    flex-direction: row-reverse;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-bottom: solid 1px #e0e0e0;
    height:1rem;
}

#D_CP_Manage_centre_droite_haut_apply,
#D_CP_Manage_centre_droite_haut_create,
#D_CP_Manage_centre_droite_haut_delete_apply{
    border-radius: 3px;
    background-color: #3949ab;
    color:white;
    padding:0.2rem 0.9rem 1rem 0.9rem;
    cursor: pointer;
}

#D_CP_Manage_centre_droite_haut_cancel{
    color: #3949ab;
    padding:0.2rem 0.7rem 0.2rem 0.7rem;
    cursor: pointer;
    margin-right:0.5rem;
}

#D_CP_Manage_centre_droite_milieu{
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    display: flex;
    flex-direction: row;
    max-height: calc(100% - 2.4rem);
    overflow-y: auto;
}

#D_CP_Manage_centre_droite_haut_no_write{ 
    background-image: url(/images/help.svg);
     background-repeat:no-repeat;
     background-size: 0.9rem 0.9rem;
     background-position: left; 
     margin: 0.2rem 0rem 0.2rem 0;
    padding-left: 1.3rem;
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: oblique;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #9e9e9e;
}

#D_CP_Manage_centre_droite_haut_edit{
    background-image: url(/images/edit_bleu.svg);
     background-repeat:no-repeat;
     background-size: 0.75rem 0.75rem;
     background-position: center; 
     cursor:pointer;
     width:0.75rem;
     height:0.75rem;
     margin: 0.25rem 0.5rem 0 0;
 }

 #D_CP_Manage_centre_droite_haut_delete{
     background-image: url(/images/delete_bleu.svg);
     background-repeat:no-repeat;
     background-size: 0.75rem 0.75rem;
     background-position: center; 
     cursor:pointer;
     width:0.75rem;
     height:0.75rem;
     margin-top: 0.25rem;
  }

#D_CP_Manage_centre_droite_milieu_gauche{
    flex:3;
    border-right: solid 1px #eeeeee;
}

#D_CP_Manage_centre_droite_milieu_droite{
    flex:2;
    padding:0 0.5rem 0 0.5rem;
}


#D_CP_Manage_bas{
    height:3rem;
    position: relative; 
    border-top: solid 1px #e0e0e0;
}

#D_CP_Manage_bas_close{
    position: absolute;
    right:0.5rem;
    top:1rem;  
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3949ab;
    cursor: pointer;
}

#D_CP_Manage_bas_close:hover{
font-weight: bold;
}

.D_CP_Manage_centre_gauche_CP_UN{
    height:2rem;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #eeeeee;
    border-left: solid 5px transparent;
}

.D_CP_Manage_centre_gauche_CP_UN.select{
    border-left: solid 5px #3949ab;
    background-color: #fafafa;
}

.D_CP_Manage_centre_gauche_CP_UN:hover{    
    background-color: #3949ab;
    color: white;
}

.D_CP_Manage_centre_gauche_CP_UN:hover div{
    color: white;
}

.D_CP_Manage_centre_gauche_CP_UN_label{
    margin-left: 0.5rem;;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: oblique;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    max-width: calc(100% - 4rem);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.D_CP_Manage_centre_gauche_CP_UN_image{
    background-image: url(/images/cp_partage.svg);
    background-repeat:no-repeat;
    background-size: 0.9rem 0.9rem;
    background-position: center; 
    cursor:pointer;
    width:0.9rem;
    height:0.9rem;
    margin-left:0.5rem;
}

.D_CP_Manage_centre_gauche_CP_UN:hover .D_CP_Manage_centre_gauche_CP_UN_image{
    background-image: url(/images/cp_partage_select.svg);
}

#D_CP_Manage_centre_droite_milieu_titre,
#D_CP_Manage_centre_droite_milieu_valeur,
#D_CP_Manage_centre_droite_milieu_droite_type,
#D_CP_Manage_centre_droite_milieu_droite_owner{
    display: flex;
    flex-direction: row;
}

#D_CP_Manage_centre_droite_milieu_titre_label,
#D_CP_Manage_centre_droite_milieu_valeur_label,
#D_CP_Manage_centre_droite_milieu_droite_type_label,
#D_CP_Manage_centre_droite_milieu_droite_owner_label{
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
    margin:0.2rem 0.5rem 0 0;
}

#D_CP_Manage_centre_droite_milieu_titre_value,
#D_CP_Manage_centre_droite_milieu_valeur_value,
#D_CP_Manage_centre_droite_milieu_droite_type_value,
#D_CP_Manage_centre_droite_milieu_droite_owner_value{
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: oblique;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
}

.D_CP_Manage_centre_droite_milieu_valeur_values_liste_un{
    display:flex;
    flex-direction: row;
    margin-bottom: 0.1rem;
}

.D_CP_Manage_centre_droite_milieu_valeur_values_liste_un_delete{
    
    background-image: url(/images/delete_bleu.svg);
    background-repeat:no-repeat;
    background-size: 0.9rem 0.9rem;
    background-position: center; 
    cursor:pointer;
    width:0.9rem;
    height:0.9rem;
    margin:0.1rem 0 0 0.5rem;
}

#I_CP_Manage_centre_droite_milieu_titre_value.editable,
.I_CP_Manage_centre_droite_milieu_valeur_values_liste_un_valeur{
    border: 0;  
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    pointer-events: none;
    max-width: calc(100%);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#D_CP_Manage_centre_droite_milieu_valeur_values_liste_add_value{
    margin-top:0.5rem;
    font-size: 0.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c80f6;
    cursor:pointer;
}

.D_CP_Manage_centre_droite_milieu_valeur_values_liste_add_un{
    display: flex;
    flex-direction: row;
    width:100%;
    max-width: 100%;;
}

#D_CP_Manage_centre_droite_milieu_delete{
    margin: 1rem 0.5rem 0 0.5rem;
}

#D_CP_Manage_centre_droite_milieu_delete_label{
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

}

#D_CP_Manage_centre_droite_milieu_delete_action{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

#D_CP_Manage_centre_droite_milieu_delete_action_cancel{
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3949ab;
    cursor:pointer;
}

#D_CP_Manage_centre_droite_milieu_delete_action_valider{  
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 3px;
    background-color: #3949ab;
    padding: 0.1rem 0.7rem 0.1rem 0.7rem;
    margin-right: 1.5rem;
    cursor: pointer;
}