
.D_select_perso{
    position:relative;
    height:100%;
    background-image: url(/images/div_open_list.svg);
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 0.4rem;
    min-height: inherit;
}

.D_select_perso_select{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor:pointer;
    height:100%;
    min-height: inherit;
    box-sizing: border-box;
    padding-top:0.5rem;
}

.D_select_perso:focus{
    outline: none;
}

.D_select_perso_select:hover, .D_select_perso_select.hover{
    background-color: #e8eaf6;
}

.D_select_perso_select_value{
    width:100%;
    max-width: 100%;
    position: absolute;
    background-color: white;
    z-index:30;
    top:2rem;
    border-radius: 1px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.D_select_perso_select_value_un{
    /*text-overflow: ellipsis;*/
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /*white-space: nowrap;*/
    max-width: 100%;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    padding: 0.5rem 0.3rem 0.5rem 0.3rem;
    cursor: pointer;
}

.D_select_perso_select_value_un:hover{
    background-color: #e8eaf6;
}

.D_select_perso_select_value_un{
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    cursor:pointer;
    padding-left: 1.5rem;
}

.select_perso_simple .D_select_perso_select_value_un.selected{
	background-image: url(/images/select_simple_check.svg);
	background-repeat:no-repeat;
	background-size: 0.7rem;
	background-position: 0.4rem;
}

.select_perso_multiple .D_select_perso_select_value_un{
	background-image: url(/images/select_multiple.svg);
	background-repeat:no-repeat;
	background-size: 0.8rem;
	background-position: 0.4rem;
}

.select_perso_multiple .D_select_perso_select_value_un.selected{
	background-image: url(/images/select_multiple_check.svg);
}

.D_select_perso_select_value_lst{
    width:100%;
    max-height: 18.5rem;
    overflow-y: auto;
}

.D_select_perso_select_value_apply{
    padding: 1rem 0 1rem 1.5rem;
    margin:0 0.1rem 0 0.1rem;
    border-top: solid 1px #e0e0e0;
    cursor:pointer;
}

.D_select_perso_select_value_apply:hover{
    background-color: #e8eaf6;
}
