#root .tooltipcssprojet{
	background-color: white;
	color:black;
	border:1px solid #3949ab;
}
#root .tooltipcssprojet.place-top:after {
		border-top-color: #3949ab !important;
		border-top-style: solid !important;
		border-top-width: 6px !important;
}

.treeloadgif{
	background-image: url(/images/load.gif);
	background-repeat:no-repeat;
	background-size: 1.9rem;
	background-position: left center;
	height:2rem;
}

.TreeNodeContainerGeneralInfo{
    display: flex;
    align-items: center;
}
	
.TreeNodeContainerInfo:hover,
.TreeNodeContainerInfo.selectnode {
    background-color: #e8eaf6;
    color:#212121;
}

.TreeNodeContainerInfo{
	width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 1.8rem;
}

.TreeNodeHeader{
	margin-left: 0.5rem;
	cursor:pointer;
}

.is_liste .TreeNodeHeader:hover .S_TreeNodeHeader,
.is_workfile .TreeNodeHeader:hover .S_TreeNodeHeader{
	text-decoration: underline;
}

.TreeNodeIconeHearder{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1.8rem;
	color: #212121;
	padding-right: 1rem;
	padding-left: 0.5rem;
}

.TreeNodeContainerInfo.selectnode .TreeNodeIconeHearder{
	background-color:#3949ab;
	color:white;
}

.TreeNodeIcone {
	cursor: pointer;
}

.TreeNodeToogle, .TreeNodeHeader, .D_clickdiv{
    float:left;
}

.TreeNodeToogle{
	margin-right:0.5rem;
	cursor: pointer;
}

.D_clickdiv{
	display:flex;
	align-items: center;
	justify-content: center;
}

.TreeNodePoint{
	display:flex;
	flex-direction: row;
	align-items: center;
    float:right;
    padding:0 0.5rem 0 0.5rem;
    cursor:pointer;
	color:black;
	min-height: 1.2rem;
	visibility: hidden;
	
}

.TreeNodeContainerInfo:hover .TreeNodePoint{
	visibility: visible;
}

.TreeNodeContainerInfo.selectnode .TreeNodePoint{
	visibility: visible;
}

.S_TreeNodePoint{
	color:#3949ab;
}



.fleche_tree_open, .fleche_tree_close, .icone_folderopen, .icone_folderclose, .icone_liste, .icone_workfile{
	background-repeat:no-repeat;
	background-size: 1.0rem;
	background-position: left center;
	min-height:1.8rem;
	width:1.2rem;
}

.fleche_tree_open_hide, .fleche_tree_close_hide, .icone_folderopen_hide, .icone_folderclose_hide, .icone_liste_hide, .icone_workfile_hide{
	display:none;
}

.fleche_tree_open{
	background-image: url(/images/flechetreeopen.svg);
}

.fleche_tree_close{
	background-image: url(/images/flechetreeclose.svg);
}

.icone_folderclose{
	background-image: url(/images/folderclose.svg);
}

.icone_folderopen{
	background-image: url(/images/folderopen.svg);
}

.icone_liste{
	background-image: url(/images/list.svg);
}

.icone_workfile{
	background-image: url(/images/workfile.svg);
}

.TreeNodeContainerInfo.selectnode .icone_folderclose{
	background-image: url(/images/folderclosewhite.svg);
}

.TreeNodeContainerInfo.selectnode .icone_folderopen{
	background-image: url(/images/folderopenwhite.svg);
}

.TreeNodeContainerInfo.selectnode .icone_liste{
	background-image: url(/images/listwhite.svg);
}

.TreeNodeContainerInfo.selectnode .icone_workfile{
	background-image: url(/images/workfilewhite.svg);
}