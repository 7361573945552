#D_DocumentParcours{
    position: absolute;
	right: 0.1rem;
	top:0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding:1rem 1rem 1rem 0;
}

.listedoc #D_DocumentParcours{
	display:initial;
	position:initial;
	width:calc( 100% - 1rem);
	border-bottom: 0.1rem solid #eeeeee;
	padding:0.2rem 1rem 0.2rem 0;
}

#I_DocumentParcours_prec, #I_DocumentParcours_next{
	margin-left: 1em;
	max-height : 1em;
	max-width  : 1em;
	width      : 1rem;
	cursor     : pointer;
	position: relative;
	top        : 3px
}

#S_DocumentParcours{
    font-size:0.88em;
}

#D_DocumentParcours_retour{
	flex:1;
	float:left;
	cursor:pointer;
	margin-top: 0m;
    display: flex;
    align-items: center;
}

.listedoc #D_DocumentParcours_retour{
	margin-top: 6px;
}

.listedoc #D_DocumentParcours_pages {
	margin-top: 6px;
}

#D_DocumentParcours_retour_inf{
    width:1rem;
    height:1rem;
    background-image: url(/images/inf_fampat.svg);
	background-repeat:no-repeat;
	background-position: center;
    background-size:1rem 1rem;
	cursor: pointer;
	margin-right:0.5rem;
}

.LIST #D_DocumentParcours_retour_inf,
.WORKFILE #D_DocumentParcours_retour_inf{
    background-image: url(/images/inf_projet.svg);
}

.ALERTE #D_DocumentParcours_retour_inf{
    background-image: url(/images/inf_alerte.svg);
}

.D_retour_search{
	color:#3E8299;
}

.D_retour_projet{
	color:#3949ab;
}

.D_retour_alert{
	color:#d84315;
}

#I_DocumentParcours_retour{
	width:2em;
	height:1.8em;
	vertical-align: middle;
}

#D_DocumentParcours_pages{

}

.listedoc #D_DocumentParcours_pages {
	position  : absolute;
	display   : block;
	width     : 50%;
	left      : 25%;
	text-align: center;
}

.listedoc .highlight-configurator .__react_component_tooltip.place-bottom::before {
	left: auto;
	right: 30px;
}

.listedoc .highlight-configurator .__react_component_tooltip.place-bottom::after {
	left: auto;
	right: 32px;
}

.listedoc .highlight-configurator {
	position: absolute;
	right: 70px;
	top: 10px;
}

/*
gestion du bouton d'action
*/

#D_DocumentParcours_actions{
	flex:1;
}

#D_DocumentParcours_export{
    width:2rem;
    height:2rem;
    background-image: url(/images/menu/menubuttongreen.svg);
	background-repeat:no-repeat;
	background-position: center;
    background-size:contain;
	cursor: pointer;
	margin-left:1rem;
	margin-right:0.2rem;
	float:right;
}


#D_DocumentParcours_export:hover{
    background-image: url(/images/menu/menubuttongreenhover.svg);
}

.LIST #D_DocumentParcours_export,
.WORKFILE #D_DocumentParcours_export{
    background-image: url(/images/menu/menubuttonblue.svg);
}

.LIST #D_DocumentParcours_export:hover,
.WORKFILE #D_DocumentParcours_export:hover{
    background-image: url(/images/menu/menubuttonbluehover.svg);
}

.ALERTE #D_DocumentParcours_export{
    background-image: url(/images/menu/menubuttonred.svg);
}

.ALERTE #D_DocumentParcours_export:hover{
    background-image: url(/images/menu/menubuttonredhover.svg);
}

.hide{
    display:none;
}

.SM_DocumentParcours_export{
	z-index: 1;
}