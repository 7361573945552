.highlight-configurator > .__react_component_tooltip::before,
.highlight-configurator > .__react_component_tooltip::after{
    content:none !important;
}

.highlight-configurator .__react_component_tooltip.show{
    /* Force tooltip no transparency */
    opacity         : 1;
    padding         : 0;
    border          :0;
    border-radius   :3px;
    box-shadow      : 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.highlight-configurator {
    text-align: center;
}

.highlight-configurator-button {
    font-size    : 11px;
    cursor       : pointer;
    color        : #212121;
    border-radius: 2px;
    display      : inline-block;
    white-space  : nowrap; 
    line-height: 17px;
    background-image: url(/images/pen.svg);
	background-repeat:no-repeat;
	background-position: 0.4rem 0.4rem;
    background-size:0.78rem;
    padding: 0.3rem 0.4rem 0.2rem 1.4rem;

}

.highlight-configurator-button:hover,
.highlight-configurator.is-opened .highlight-configurator-button {
	background-color: #e9e9e9;
}

.highlight-configurator-button img.edit {
    margin-right: 5px;
    top         : 3px;
    position    : relative;  
}

#highlight-configurator-tooltip {
    pointer-events: auto !important;
    padding: 10px;
}

.highlight-configurator-content  {
    text-align: left;
}

.highlight-configurator-content  .header {
    display   : inline-block;
    width     : 100%;
    text-align: center;
    background-color: #fafafa;
}


.highlight-configurator-content  .header {
    color      : #212121;
    font-size  : 14px;
    font-weight: normal;
    margin     : 0;
    line-height: 40px;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlight-configurator-content .header .switch-all {
    cursor  : pointer;
    display : inline-block;
    width:2.5rem;
    height:2.5rem;
	background-repeat:no-repeat;
	background-position: center;
	background-size:2.5rem;
	background-image: url("/images/switch-off.svg");

}

.highlight-configurator-content .header .switch-all.enabled {
	background-image: url("/images/switch-on.svg");
}

.highlight-configurator-content  .header h2 .header_title {
    position: relative;
    top     : -10px;
}


.highlight-configurator-content .__react_component_tooltip{
    pointer-events: auto !important;
    padding: 10px;
}

.highlight-configurator-content .highlights-block {
    border-top: solid 1px #e0e0e0;
    padding   : 10px 20px;
    position  : relative;
}


.highlight-configurator-content .highlights-block h3{
    position      : relative;
    font-size     : 9px;
    line-height   : 11px;
    text-transform: uppercase;
    color         : #757575;  
    font-weight: initial;
    font-stretch: normal;
    font-style: initial;
    line-height: normal;
    letter-spacing: normal;
}

.highlight-configurator-content .highlight{
    position   : relative;
    margin     : 5px 0;
    text-align : left;
    line-height: 22px;
}

.highlight-configurator-content .highlight .remove {
    display   : none;
    cursor    : pointer;
    width     : 20px;
    text-align: center;
}

.highlight-configurator-content .highlights-block.custom .highlight:hover .remove {
    display: inline-block;
}

.highlight-configurator-content .highlight .remove img{
    position: relative;
    top     : 4px;
    vertical-align: top;
}

.highlight-configurator-content .highlight .color-choice {
    padding: 7px;
}

.highlight-configurator-content .color-indicator {
    position     : relative;
    cursor       : pointer;
    display      : inline-block;
    width        : 14px;
    height       : 14px;
    border       : 1px solid #9e9e9e;
    border-radius: 16px;
    margin       : 0 4px;
    top          : 2px;
}

.highlight-configurator-content  .color-indicator:hover {
    box-shadow: 0px 0px 2px 5px #e7e7e7;
    
}

.highlight-configurator-content .highlight-content-editable {
    text-align    : left;
    display       : inline-block;
    margin        : 0 5px;
    line-height   : 15px;
    width         : calc(100% - 47px);
    font-size     : 12px;
    border        : solid 1px #ffffff;
    padding       : 2px;
    vertical-align: top;
    
}

.highlight-configurator-content .highlights-block.custom .highlight:hover .highlight-content-editable,
.highlight-configurator-content .highlight-content-editable:focus {
    border: solid 1px #eeeeee;
}

.highlight-configurator-content .highlight-content-editable:focus span {
    background-color: transparent!important;
    color           : #000000!important;
}

.highlight-configurator-content .highlights-block.custom .highlight:hover .highlight-content-editable{
    width      : calc(100% - 47px - 20px);
}

.highlight-configurator-content input {
    margin: 0 5px;
    position: absolute;
    top: 2px;
}


.highlight-configurator-content .highlight .enabled {
    position: relative;
    margin  : 0;
}

.highlight-configurator-content  .add-highlight {
    position   : absolute;
    right      : 5px;    
    top        : -3px;
    line-height: initial;
    font-size  : 11px;
    user-select: none;    
}


.highlight-configurator-content  .add-highlight img:hover {
    cursor       : pointer;
    border-radius: 16px;
    box-shadow   : 0px 0px 2px 5px #e7e7e7;
}

.highlight-configurator-footer  button{
    cursor          : pointer;
    font-size       : 14px;
    border          : solid 1px #ffffff;
    border-radius   : 5px;
    color           : #5a95aa;
    background-color: #ffffff;
    margin-left     : 5px;
    padding-left    : 2px 5px;
    
    text-align      : center;
}

.highlight-configurator-footer  button.ok{
    color           : #ffffff;
    background-color: #5a95aa;
}
.highlight-configurator-footer  button:hover{
    border: solid 1px #5a95aa;
}