
#newsIcone{
    width:1.5rem;
    height: 1.5rem;
	background-repeat:no-repeat;
	background-position: center;
	background-size:1.5rem 1.5rem;
    background-image: url(/images/megaphone.png);
    cursor: pointer;
    margin-right: 0.5rem;
}

#newsIcone.actif{
    background-image: url(/images/megaphone-red.png);
}
