.D_buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:100%;
    margin-top: 3em;
    margin-bottom: 0em;
}

.B_cancel, .B_valider{
    margin:0.4em;
    cursor: pointer;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    border-radius: 3px;
}

.I_lib {
    font-size: 0.8em;
    color: #212121;
    border: solid 1px #e0e0e0;
    padding: 0.5em;
}

.I_select{
    font-size: 0.8em;
    color: #212121;
    border: solid 1px #e0e0e0;
    padding: 0.5em;
}

.I_cancel, .I_valider{
    width:1em;
    height:1em;
    margin-right:0.2em;
}


