
#D_centre_alertes{
    width:100%;
    background-color: #fbe9e7;
    min-height: calc(100% - 4.7rem);
}
#D_liste_alertes{
    width:80%;
    background-color: white;
    margin: 0;
    border-left: 2px solid #D84315;
    border-right: 2px solid #D84315;
    min-height: 100%;
    margin: auto;
}

.D_une_alerte{
    /*max-width:1000px;*/
    background-color: white;
    color: black;
    font-size: 0.9rem;
    margin: 0 0 0 0.5rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
    background-image: url(/images/redalert.svg); 
    background-repeat: no-repeat ;
    background-position:0.5rem 0.8rem;
    display: table;
}

.B_view{
    margin-left: 0;
    float:right;
    color: #D84315;
    background-color: white;
    font-size: 1.0rem;
    border: 0.1rem solid #D84315;
    border-radius: 0.2rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    cursor:pointer;
}
.B_view:hover{
    color: white;
    background-color: #D84315;
}
.D_monitoring2{
    background-color: white;
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid #D84315;
}
.D_liste-alertes{
    background-color: white;
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem;
}
.S_titre_alerte{
    width:75%;
    color: #D84315;
    font-size: 1.2rem;
    font-weight: bold;
    font-weight: bold;
    vertical-align: middle;
}
.S_stitre1_alerte{
    color: #757575;
    font-size: 0.8rem;
    vertical-align: middle;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
}
.S_stitre2_alerte{
    color: #9e9e9e;
    font-size: 0.8rem;
    font-style: italic;
    vertical-align: middle;
    margin-top: 0.2rem;
}
.S_nom_alerte{
    width:55%;
    margin-left: 1rem;
    padding-left:1.5rem;
    vertical-align: middle;
    display: table-cell;
}
.S_date_alerte{
    width:25%;
    vertical-align: middle;
    display: table-cell;
    color: #888888;
}
.S_type_alerte{
    width:20%;
    vertical-align: middle;
    display: table-cell;
    color: #888888;
}
.S_menu_alerte{
    width:5%;
    vertical-align: middle;
    display: table-cell;
}

.S_a_name{
    width:60%;
    color: #D84315;
    margin-left: 0px;
    margin-bottom: 1rem;
    display:inline-block;
}
.S_a_period{
    width:35%;
    color: #D84315;
    display:inline-block;
    text-align: right;
}
.D_titre2 {
    color: #212121 !important;
    margin-bottom: 2rem;
}
.B_cancel_alert, .B_valider_alert{
    color: #D84315;
    border: 0.1px solid #D84315;
    background-color: white;
}

.B_cancel_alert:hover, .B_valider_alert:hover{
    color: white;
    background-color: #D84315;
}
