#D_droite_graphes{
	width:calc(100% - 2rem);
	max-width:calc(100% - 2rem);
	max-height: calc(100% - 1rem);
	height: calc(100% - 1rem);
	overflow-y: auto;
	margin: 0.5rem 1rem 0 1rem;
	border-radius: 7px;
	border: 1px solid #e0e0e0;
	padding: 1rem 1rem 0 1rem;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

#D_droite_graphes_1{
	height:6rem;
	max-width:100%;
}

#D_droite_graphes_2{
	height:calc((100% - 6rem) * 0.35 );
	max-width:100%;
}

#D_droite_graphes_3{
	height:calc((100% - 6rem) * 0.50 );
	max-width:100%;
}

#D_viewer_graphes{
	width:99%;
	background-color: white;
	height: 68%;
	min-height:300px;
}

#D_viewer_graphes_load{
	width:100%;
	min-height:300px;
}
.D_droite_graphes_un{
	width:100%;
	height:20em;
	background-image: url(/images/graphe1.png);
	background-repeat:no-repeat;
	background-position: center; 
	background-size: contain; 
}

.D_graphes_next{
    width:100%;
    text-align:center;
}
.B_next{
	width:48px;
	height:48px;
	margin-left: 20px;
	background-image: url(/images/right.svg); 
	background-repeat: no-repeat ;
	background-position: center;
}
.B_prev{
	width:48px;
	height:48px;
	background-image: url(/images/left.svg); 
	background-repeat: no-repeat ;
	background-position: center;
}
.titre-class-stat{
    text-align:center;
    font-size: 18px;
}
.table-class-techno{
    width:80%;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
}
.td-class-techno{
    font-size: 12px;
    font-weight: bold;
    color: #888888;
}
.bar-class-tempo
{
	width: 100%;
	height: calc(100% - 18px);
}
.bar-class-pa
{
	width: 100%;
	height: 100%;
}
.bar-class
{
	width: 100%;
	height: 95%;
}
.bubble-class
{
	width: 100%;
	height: 100%;
}
.map-class{
	margin-left: 20px;
	width: 95%;
	height: 100%;
}