#D_liste_doc_NPL_info_concept{
	font-size: 0.75rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: oblique;
	line-height: normal;
	letter-spacing: normal;
	color: #9e9e9e;
	margin:0.3rem 3.5rem 0 1.5rem;
	padding-left: 1.5rem;
	background-image: url(/images/information-outline.svg);
	background-repeat:no-repeat;
	background-size: 0.9rem;
	background-position: left;
}

#S_liste_doc_NPL_info_concept{
	color:#5a95aa;
	font-weight: bold;
}

.tooltipcsslistedoc,
.FAMPAT .tooltipcsslistedoc,
.sim .tooltipcsslistedoc,
.semantic .tooltipcsslistedoc{
	background-color: white;
	color:black;
	border:1px solid #3E8299;
}

.tooltipcsslistedoc.place-top:after,
.FAMPAT .tooltipcsslistedoc.place-top:after,
.sim .tooltipcsslistedoc.place-top:after,
.semantic .tooltipcsslistedoc.place-top:after {
		border-top-color: #3E8299;
		border-top-style: solid ;
		border-top-width: 6px ;
}

.LIST .tooltipcsslistedoc,
.WORKFILE .tooltipcsslistedoc{
	background-color: white;
	color:black;
	border:1px solid #3f51b5;
}
.LIST .tooltipcsslistedoc.place-top:after,
.WORFILE .tooltipcsslistedoc.place-top:after {
		border-top-color: #3f51b5;
		border-top-style: solid ;
		border-top-width: 6px ;
}

.ALERTE .tooltipcsslistedoc{
	background-color: white;
	color:black;
	border:1px solid #e64a19;
}
.ALERTE .tooltipcsslistedoc.place-top:after {
		border-top-color: #e64a19;
		border-top-style: solid ;
		border-top-width: 6px ;
}

#D_liste_doc_centre {
	position:relative;
	flex: 3;
    font-weight: 400;
    font-style: normal;
	max-height:100%;
}

.listedoc #D_liste_doc_centre{
	display:none;
}

.rechercheplus #D_liste_doc_centre {
	display: none;
}

#D_liste_doc{
	position: relative;
	margin:0;
	max-height: 100%;
	height: 100%;
    display:flex;
	flex-direction: column;
	flex:auto;
}

#D_liste_doc_option{
	line-height: 42px;
}

#D_liste_doc_option_gauche{
	display:flex;
	flex-direction: row;
}

#S_liste_doc_nb_resultat{
	margin-right:2rem;
}

#S_liste_doc_nb_resultat, #S_liste_doc_sort_by, #S_liste_doc_grouped_by, #S_liste_doc_results_page{
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #757575;
	margin-left : 1.0rem;
}

#SO_liste_doc_sort_by, #SO_liste_doc_grouped_by{
	margin-top: -0.3rem;
}

#SO_liste_doc_sort_by, #SO_liste_doc_grouped_by, #SO_liste_doc_results_page{
	color: #212121;
	display: inline-block;
}

#S_liste_doc_nb_select{
	margin-right:1rem;
	font-size:0.9rem;
	color:#3E8299;
}

#D_liste_doc_action_liste_doc{
	display       : flex;
	flex-direction: row;
	flex          :auto;
	align-items   :center;
	position      : absolute;
	top           : 0;
	right         : 4px;
}

#D_liste_doc_action_liste_doc .highlight-configurator {
	margin-right: 10px;
}

#D_liste_doc_ajouter{
	color: #545252;
	border: 0.1rem solid  #545252;
	border-radius:0.5rem;
	padding: 0.5rem;
	margin:0 1rem 0 1rem;
}

#D_liste_doc_liste{
	width:100%;
	overflow-y:auto;
}

.D_liste_doc_liste_select{
	height: 10rem;
	min-width: 1.5rem;
	cursor: pointer;
	display:flex;
	justify-content: center;
	align-items: center;
}

.D_liste_doc_liste_select_case{
	border-width:0.07rem;
	border-style:solid;
	border-color:#9e9e9e;
	background-color: white;
	width:0.8rem;
	height:0.8rem;
}

.D_liste_doc_liste_info{
	cursor:pointer;
	width:100%;
}

.D_liste_doc_liste_info_NPL{
	cursor:pointer;
	width:100%;
	margin-left:0.5rem;
}

.D_liste_doc_liste_select_case.selectDoc{
	background-image: url(/images/check.svg);
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center;
}

.D_liste_doc_liste_select_case.selectDoc,
.FAMPAT .D_liste_doc_liste_select_case.selectDoc,
.sim .D_liste_doc_liste_select_case.selectDoc,
.semantic .D_liste_doc_liste_select_case.selectDoc
{
	border-color: #3E8299;
	background-color:#3E8299;
}

.LIST .D_liste_doc_liste_select_case.selectDoc,
.WORKFILE .D_liste_doc_liste_select_case.selectDoc{
	border-color: #3949ab;
	background-color:#3949ab;
}

.ALERTE .D_liste_doc_liste_select_case.selectDoc{
	border-color: #D84315;
	background-color:#D84315;
}


.D_liste_doc_liste_doc{
	display       : flex;
	padding       : 10px 0;
	flex-direction: row;
	align-items   :center;
	overflow      :hidden;
	max-height    :9.3rem;
	border-left   : 0.25rem solid transparent;
	transition: background-color 200ms;
}

.D_liste_doc_liste_doc:hover {
	background: #F5F5F5;
}

.activeDoc{
	background-color: #fafafa;
}

.activeDoc,
.FAMPAT .activeDoc,
.sim .activeDoc,
.semantic .activeDoc{
	border-left: 0.25rem solid #3E8299;
}

.LIST .activeDoc, .WORKFILE .activeDoc{
	border-left: 0.25rem solid #3949ab;
}

.ALERTE .activeDoc{
	border-left: 0.25rem solid #D84315;
}

.D_liste_doc_liste_pn_affi{
	padding: 0.0em;
	overflow:hidden;
	max-height:1.2rem;
	line-height:1.0rem;
	margin-bottom:0.0rem;
	margin-top:0.0rem;
}

.D_liste_doc_liste_date_aff_source{
	padding: 0.0em;
	overflow:hidden;
	max-height:0.8rem;
	line-height:0.9rem;
	margin-bottom:0.3rem;
	margin-top:0.0rem;
	display: flex;
	flex-direction: row;
	font-size: 0.67rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #212121;
	position: relative;
}

.D_liste_doc_liste_date_aff_source_date {
	margin-right:1.5rem;
}

.D_liste_doc_liste_date_aff_source_date_source {
	margin-right:5rem;
}

.D_liste_doc_liste_date_aff_source_date_aff{
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
	position: absolute;
	margin-left: 14rem;
	max-width: calc(100% - 15rem);

}

.D_liste_doc_liste_dates{
	padding: 0.0rem;
	overflow:hidden;
	max-height:1.2rem;
	line-height:1.0rem;
	margin-bottom:0.2rem;
	margin-top:0.0rem;
}
.S_liste_doc_liste_pn, .S_liste_doc_liste_affi{
	/*border-radius:0.5em;*/
	padding: 0 0.0 0 0.0;
	margin: 0 0.0 0 0.0;
	border:0;
	color: #546e7a;
	font-size: 0.67rem;
}
.S_liste_doc_liste_date {
	padding: 0 0.0 0 0.0;
	margin: 0 0.0 0 0.0;
	color: #999999;
	font-size: 0.67rem;
}


.D_liste_doc_liste_etoile{
	float:right;
	max-height:0.7rem;
	width:5rem;
}

.D_liste_doc_liste_etoile >img{
	max-height:0.7rem;
	max-width:0.7rem;
	width:100%;
}

/*
gestion de la taille du titre du doc

*/
.D_liste_doc_liste_titre{
	margin-right:1rem;
	margin-bottom: 0.4rem;
	color:#3C80F6;
	word-break: break-word;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-ms-word-break: break-all;
	word-wrap: break-all;
}

.D_liste_doc_liste_titre:hover{
	text-decoration: underline;
  text-decoration-color: #3C80F6;
}

.D_liste_doc_liste_titre >p {
	font-size    : 0.85rem;  
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color        :#3C80F6;
	overflow     : hidden;
	position     : relative;
	line-height  : auto;
	max-height   : 2rem;
	text-align   : justify;
	margin       : 0 -1rem 0 0;
	padding-right: 1rem;
}
.D_liste_doc_liste_titre >p:before {
	content: '...';
	position: absolute;
	right: 0;
	bottom: 0;
}
.D_liste_doc_liste_titre >p:after {
	content: '';
	position: absolute;
	right: 0;
	width: 1rem;
	height: 1rem;
	margin-top: 0.2rem;
	background: white;
}

.D_liste_doc_liste_doc.activeDoc .D_liste_doc_liste_titre >p:after{
	background: #fafafa;
}

.D_liste_doc_liste_doc:hover .D_liste_doc_liste_titre >p,
.D_liste_doc_liste_doc:hover .D_liste_doc_liste_titre >p:after{
background: #F5F5F5;
}

.D_liste_doc_liste_image{
	min-width: 12%;
	min-height:7rem;
	margin-right:0.8rem;
	background-image: url(/images/doc_image.png);
	border: solid 1px #eeeeee;
	background-color: #FFFFFF;
	background-repeat:no-repeat;
	background-size: contain;
	background-position: center;
	cursor:pointer;
	float: left;
}

/*
Gestion de la taille du abstract pour les ...

*/
.D_liste_doc_liste_abstract{
	margin-right:1rem;
	color:#616161;
    /*word-break: break-all;*/
	word-break: break-word;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-ms-word-break: break-all;
	word-wrap: break-all;
}

.D_liste_doc_liste_abstract >p {
	font-size     :0.7rem;
	font-weight   : normal;
	font-stretch  : normal;
	font-style    : normal;
	letter-spacing: normal;
	overflow      : hidden;
	position      : relative;
	line-height   : auto;
	max-height    : 3.9rem;
	text-align    : justify;
	margin        : 0 -1rem 0 0;
	padding-right : 1rem;
}
.D_liste_doc_liste_abstract >p:before {
	content: '...';
	position: absolute;
	right: 0;
	bottom: 0;
}

.D_liste_doc_liste_abstract >p:after {
	content: '';
	position: absolute;
	right: 0;
	width: 1rem;
	height: 1rem;
	margin-top: 0.2rem;
	background: white;
}

.D_liste_doc_liste_doc.activeDoc .D_liste_doc_liste_abstract >p:after{
	background: #fafafa;
}

.D_liste_doc_liste_doc:hover .D_liste_doc_liste_abstract >p:after,
.D_liste_doc_liste_doc:hover .D_liste_doc_liste_abstract >p{
	background: #F5F5F5;
	}

#D_liste_doc_gestion_page{
	margin:1rem 0 1rem 0.5rem;
}

#I_liste_doc_gestion_page_debut, #I_liste_doc_gestion_page_prec,
#I_liste_doc_gestion_page_next, #I_liste_doc_gestion_page_fin{
	margin-left: 1rem;
	max-height: 1rem;
	max-width: 1rem;
	cursor: pointer;
}

#D_droite_close{
	position: absolute;
	top: 0.8rem;
	font-size:0.7rem;
	right:0.1rem;
	width:0.6rem;
	height:0.6rem;
	cursor:pointer;
	z-index:4;
	background-repeat:no-repeat;
	background-position: center;
	background-size: contain;
}

#D_droite_close.reduitsvg{
	right:-1.2rem;
    z-index: 2;
}

.LIST #D_droite_close.reduitsvg,
.WORKFILE #D_droite_close.reduitsvg,
.ALERTE #D_droite_close.reduitsvg,
.sim #D_droite_close.reduitsvg,
.semantic #D_droite_close.reduitsvg {
	top: 0.8rem;
}

#D_avancee_close {
	position           : absolute;
	left               : 10px;
	top                : -21px;
	font-size          : 0.7rem;
	width              : 0.6rem;
	height             : 0.6rem;
	cursor             : pointer;
	z-index            : 4;
	background-repeat  : no-repeat;
	background-position: center;
	background-size    : contain;
}

#D_avancee_close.expansesvg{
	left: -1.3rem;
	top:  -21px;
}

.reduitsvg{
	background-image: url(/images/page_fin.svg);
}

.expansesvg{
	background-image: url(/images/page_debut.svg);
}


@media (max-width: 915px){
	#D_liste_doc{
		margin:0.5rem;
		margin-top:0rem;
		max-height: calc(100% - 1rem);
	}

	#D_liste_doc_option{
		/* font-size: 0.8rem; */
	}

	.D_liste_doc_liste_doc{
		margin-top: 0.5rem;
	}

	#SO_liste_doc_sort_by, #SO_liste_doc_grouped_by, #SO_liste_doc_results_page{
		margin : 0rem;
	}

}

