
.autoCompleteOrbit{
    padding:0.1rem 0 0 0.3rem;
}

.autcomplete_option_val_un{
    margin-bottom: 0.1rem;
    padding: 0.25rem 0.5rem 0.25rem 0.1rem;
    cursor:pointer;
}

.autcomplete_option_val_un:hover{
    background-color: #eeeeee;
}

.autcomplete_option_val_un.logo{
	background-repeat:no-repeat;
	background-position: left;
    background-size:2rem;
    padding-left:2.2rem;
}

.autcomplete_option_val_un.selected{
    background-color: #eeeeee;
}

.autoCompleteOption .autcomplete_option_val_un{
    border:none;
}

.autcomplete_option_val_un_info.country{
	background-repeat:no-repeat;
	background-position: left;
    background-size:1.1rem;
    padding-left:1.2rem;
}

.AutoCompleteOrbitD{
    position: relative;
    display: flex;
    justify-content: center;
}

.AutoCompleteOrbitDF{
    position: absolute;
    top: -3px;
    left: 2px;
    min-width: 100%;
    background-color: #ffffff;
    z-index:99;    
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    color:#212121;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    min-height: 7rem;
    padding: 3px 1px;
}

.AutoCompleteOrbitInput{
    border:0;
    min-width: 100%;
    max-width: 100%;
    padding:0.25rem 0 0.6rem 0.1rem;
}

.AutoCompleteOrbitInput:focus{
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
}

.AutoCompleteOrbitOtpionList{
    padding:0.25rem 0 0 0;
    border-top: solid 1px #e0e0e0;
}

.AutoCompleteOrbitD textarea, .AutoCompleteOrbitDF textarea {
    width             :150px;
	min-width         : 2rem;
	max-width         : 100%;
	color             : black;
	min-height        : 1.2rem;
	height            : 24px;
	font-family       : 'roboto';
	font-size         : 0.8rem;
    font-weight       : normal;
    font-style        : normal;
    font-stretch      : normal;
    line-height       : normal;
    letter-spacing    : normal;
	overflow          : hidden;
	resize            : none;
	border            :none;
    box-shadow        : none !important;
	-webkit-box-shadow: none;
    -moz-box-shadow   : none;
	outline           :0px !important;
	-webkit-appearance:none;
	line-height       : 22px;
	padding           : 0 0 0 5px; 
}


.autcomplete_option_val_un_label{
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
}
.autcomplete_option_val_un_label_value{
    font-weight: normal;

}

