#D_recherche_simple{
    width:100%;
    height:calc(100% - 4em);
    position: relative;
    background-color:#ffffff;
}

#D_recherche_simple_centre{ 
	position: absolute;
    top: 50%;
	left: 50%;
	margin-top: -11rem;
	margin-left:-26rem;
}

#D_recherche_simple_elem{
	height: 50px;
	vertical-align: middle;
}

#IT_recherche_simple_texte{
	padding         : 0 5px;
	width           : 650px;
	font-size       : 20px; 
	line-height   	: 40px;
	border          : solid 1px #bdbdbd;
	background-color: #ffffff;
	border-right    : 0px solid transparent; 
	min-height      : 40px;
	display         : inline-block;
	vertical-align: middle;
}

#D_recherche_simple_lance {
	box-sizing         : border-box;
	background-color   : #5a95aa;
	height             :42px;
	width              :6em;
	background-image   : url(/images/recherche_blanche.png);
	background-repeat  :no-repeat;
	background-position: center;
	background-size    :1.5em 1.5em;
	display            : inline-block;
	cursor             :pointer;
	vertical-align: middle;
}


#D_recherche_simple_logo_nom{
    height: 3em;
	margin-bottom: 3rem;
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#D_recherche_simple_logo{
	float:right;
	box-sizing: border-box;
	height:6rem;
	width:4.5rem;
	background-image: url(/images/orbitexpress_icone.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:3.5rem 3.5rem;
}

#D_recherche_simple_orbit{  
	font-size: 3rem;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color:#13275c;
}

#D_recherche_simple_express{  
	margin-left: 0.2rem;
	font-size: 3rem;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #5a95aa;
}

#D_recherche_simple_option{
	height         : 3em;
	display        :flex;
	flex-direction : row;
	align-items    : center;
	justify-content: center;
	width          : 100%;
}

#D_recherche_simple_advance{
	font-size: 0.8rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3c80f6;
	cursor:pointer;
}

#D_recherche_simple_semantic{
	font-size: 0.8rem;
	margin-left: 5rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #3c80f6;
	cursor:pointer;
}

#D_recherche_simple_questel_academy{
	position: fixed;
    bottom: 0%;
    left: 0%;
    background-image: url(/images/questel_academy_rectangle.svg);
    background-repeat: no-repeat;
    background-size: 7rem 5.7rem;
    height: 5rem;
    width: 7rem;
    cursor: pointer;
    font-size: 1rem;
    color: white;
    padding: 1.8rem 0 0 1rem;
    box-sizing: border-box;
}

@media (min-width: 646px) and (max-width: 915px){

	#D_recherche_simple_centre{ 
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -9rem;
		margin-left:-10rem;
	
	}

	#IT_recherche_simple_texte{
		width:15rem;
	}
}

@media (max-width: 645px){

	#D_recherche_simple_centre{ 
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -9rem;
		margin-left:-10rem;
	
	}

	#IT_recherche_simple_texte{
		width:14rem;
		font-size:1rem;
	}

	#D_recherche_simple_lance{
		height: 2.55rem;
		background-size:1.5rem 1.5rem;
	}
	
	#D_recherche_simple_elem{
		margin-left: 1rem;
	}

}