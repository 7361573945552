#D_centre_projet{
    width:100%;
    background-color: #e8eaf6;
    max-height: calc(100% - 4rem);
    height: calc(100% - 4rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#D_centre_projet_visuel{
    background-color: #ffffff;
    width:80%;
    height: 100%;
    min-width: 10rem;
    margin: auto;
    font-size: 1.0rem;
    line-height: 1.0rem;
    color: black;
    border-right:0.1rem solid #3949ab;
    border-left:0.1rem solid #3949ab;
}

#D_centre_projet_bas>ul{
    background-color: #ffffff;
    background-color: transparent;
}

#D_centre_projet_haut{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin:1rem 0 1rem 0;
}

#D_centre_projet_bas{
    overflow: auto;
    height: calc(100% - 4rem);
    width: 99.5%;
    margin: auto;
    position: relative;
}

#D_centre_projet_haut_add_folder,
#D_centre_projet_haut_add_list{
    border: 0.05rem solid #3949ab;
    font-size:0.8rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3949ab;
    margin-left:2rem;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    cursor:pointer;
}

#D_centre_projet.TreeInactif > ul{
    pointer-events: none;
}

#D_centre_projet_attente{
    width: 100%;
    height: calc(100% - 4.3rem);
    background-color: rgba(93, 93, 94, 0.2);
    position: absolute;
    pointer-events: none;
    left:0px;
    top:4.2rem;
    background-image: url(/images/load.gif);
	background-repeat:no-repeat;
	background-position: center;
	background-size:5rem 5rem;
}

.D_dialog_projet_buttons{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}