#D_dialog_projet_treebeard_info{
    margin-bottom: 1rem;
}

#D_treebeard{
    min-height: 15rem;
    margin-bottom:3rem;
    max-height: 20rem;
    overflow: auto;
}

#D_treebeard > ul
{
    background-color: white;
}

#D_dialog_Projet_buttons{
    margin-top: 1rem;
    position: relative;
}

#D_dialog_Projet_gauche{
    display:flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    flex-wrap: wrap;
}

#HR_dialog_projet_buttons{
	color: #e0e0e0;
	opacity: 0.4;
	width:100%;
}

#D_dialog_Projet_add_folder{
    border:0.05rem solid #5a95aa;
    color: #5a95aa;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin-right:1rem;
    cursor: pointer;
}

#D_dialog_Projet_add_list{
    border:0.05rem solid #5a95aa;
    color: #5a95aa;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-radius: 0.2rem;
    cursor: pointer;
}

#D_dialog_Projet_droite{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    right: 0;
}

.B_cancel_projet:hover{
    border: 0.05rem solid #5a95aa;
    border-radius: 0.2rem;
}

.B_cancel_projet{
    box-sizing: border-box;
    color: #5a95aa;
    margin-right:1rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width:5rem;
    text-align: center;
    cursor: pointer;
}

.B_valider_projet{
    box-sizing: border-box;
    border:0.05rem solid #5a95aa;
    background-color: #5a95aa;
    color: white;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-radius: 0.2rem;
    width:5rem;
    text-align: center;
    cursor: pointer;
    
}

.B_valider_projet:hover{
    box-shadow: 2px 2px 2px 2px #266b8454;
}