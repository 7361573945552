.D_dialog_comment{
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.D_dialog_comment #I_Dialog_lib{
    width:97%;
    border-radius: 5px;
}

.D_dialog_comment #I_Dialog_comment{
    width:100%;
    height:10rem;
}

.D_dialog_comment .rdw-option-wrapper{
    min-width: 15px;
    height: 15px;
}

.D_dialog_comment .public-DraftStyleDefault-block{
    margin:auto;
}

.D_dialog_comment .public-DraftEditor-content{
    margin-top:0.5rem;
}

.editorClassName{
    border: 1px solid grey;
    border-radius: 5px;
    padding-left:1rem;
    padding-bottom: 0.5rem;
    line-height: 1rem;
}

.D_dialog_comment .D_infos{
    margin-top: 1rem;
}

.D_dialog_comment .S_titre{
    color: black;
    font-weight: bold;
}


.D_dialog_comment .S_lib{
    color:#3949ab;
}

.D_dialog_comment .I_lib{
    margin-top:0.5rem;
    font-size: 0.8rem;;
}

.D_dialog_comment .S_lib_error{
    color:red;
}

.D_dialog_comment .rc-dialog-title{
    color:#3949ab;
}

.D_dialog_comment .rc-dialog-header{
    background-color: #fafafa;
}

.D_dialog_comment .rc-dialog-close{
    top: 0.5rem;
}

.D_dialog_comment .rc-dialog-close-x{
    color:#3949ab;
    font-weight: 100;
    font-size: 2rem;
}

.D_dialog_comment .D_buttons{
    display:flex;
    flex-direction: row;
    margin-top:0rem;
}

.D_dialog_comment .B_valider{
    background-color: #3949ab;
    border-radius: 3px;
    color:white;
    font-size: 0.9rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
}

.D_dialog_comment .B_cancel{
    color:#3949ab;
    font-size: 0.9rem;
    margin-right: 2rem;
}

