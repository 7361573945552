.SM_liste_doc_export{
    z-index:5;
    padding:0;
}

.SM_liste_doc_export .react-contexify__item__content {
    z-index    : 5;
    line-height: 20px;
}

.SM_liste_doc_export .react-contexify__submenu-arrow {
    line-height: 20px;
}

.SM_liste_doc_export .react-contexify__separator {
    margin: 0;
}


.B_liste_doc_export{
    position           : relative;
    top                : -1px; 
    width              :2rem;
    height             :2rem;
    cursor             : pointer;
    background-image   : url(/images/menu/menubuttonblue.svg);
	background-repeat  :no-repeat;
	background-position: center;
	background-size    : contain;
    background-color   : transparent;
    color              : transparent;
}

.FAMPAT .B_liste_doc_export,
.sim .B_liste_doc_export,
.semantic .B_liste_doc_export{
    background-image: url(/images/menu/menubuttongreen.svg);
}


.ALERTE .B_liste_doc_export{
    background-image: url(/images/menu/menubuttonred.svg);
}

.B_liste_doc_export:hover{
    background-image: url(/images/menu/menubuttonbluehover.svg);
}

.FAMPAT .B_liste_doc_export:hover,
.sim .B_liste_doc_export:hover,
.semantic .B_liste_doc_export:hover{
    background-image: url(/images/menu/menubuttongreenhover.svg);
}


.ALERTE .B_liste_doc_export:hover{
    background-image: url(/images/menu/menubuttonredhover.svg);
}

.I_menu{
    max-width: 1em;
    max-height:  1em;
}

.react-contexify__item__content{
    padding: 10px 20px 10px 12px;
}

.react-contexify__submenu-arrow{
    right:5px;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
    color: white;
    background-color: #5a95aa;
}

.LIST .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
.WORKFILE .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content{
    color: white;
    background-color: #1a237e;
}

.ALERTE .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content{
    color: white;
    background-color: #D84315;
}


.hide{
    display:none;
}


/**
* OINNO link button 
*/
.innoanalyse {
    margin-top: 1px; 
}
.innoanalyse .innoanalyse_div {
    padding-left: 33px; 
}
.innoanalyse.disabled {
    opacity:  1;
    background: #FFF;
}
.innoanalyse.disabled .react-contexify__item__content {
    background: #FFF !important; 
}

.innoanalyse.disabled .innoanalyse_div {
    color     : #999; 
    cursor    : pointer;
    background: #FFF;
}

.innoanalyse.disabled .innoanalyse_div::before {
    opacity:  0.5;
    background-image   : url(/images/data-analysis-symbol.svg) !important;
}

.innoanalyse .innoanalyse_div::before {
    padding-left       : 1.5rem;
	background-image   : url(/images/data-analysis-symbol.svg);
	background-repeat  :no-repeat;
	background-size    : 1.5rem;
    background-position: 0rem; 
    display            : block; 
    position           : absolute;
    left               : 12px;
    height             : 20px; 
    width              : 20px; 
    content            : " ";
}


.innoanalyse:hover .innoanalyse_div::before {
    filter: invert();
}



