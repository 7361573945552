#D_alerte_query{
    width:1.5rem;
    height:1.5rem;
    background-image: url(/images/menu/menubuttonred.svg); 
    background-repeat: no-repeat ;
    background-position:center center;
    background-size: contain;
    cursor: pointer;
}

#D_alerte_query:hover{
    background-image: url(/images/menu/menubuttonredhover.svg); 
}

