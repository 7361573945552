#D_recherche_avancee_simple_search .SM_avancee_param > div{
    padding:0;
    margin:0;
    background-color: transparent;
}

.D_avancee_and_param .SM_avancee_param{
    background-color: transparent;
}

.I_menu{
    max-width: 1rem;
    max-height:  1rem;
}


body .rc-menu-submenu-popup{
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.D_avancee_and_param .rc-menu-item{
    cursor: default;
}

#root .rc-menu-item-divider{
  margin: 0 1rem 0.5rem 1rem;
}

.D_avancee_and_param .SM_avancee_param{
    width:1rem;
    height:1rem;
    margin-right:0.5rem;
}

#D_avanceeparam_icon{
	background-image: url(/images/settings.svg);
	background-repeat:no-repeat;
	background-position: center;
	background-size:contain;
	width:1.2rem;
	height:1.2rem;
	cursor:pointer;
}

#D_avanceeparam_icon:hover{
    background-color: white;
    background-color: transparent;
    color:transparent;
}

#D_avanceeparam_text{
	width:auto;
	height:1.5rem;
	font-size: 0.7rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
  color: #3c80f6;
  background-color: white;
	cursor:pointer;
}

.D_avancee_and_param > .rc-menu, .D_avancee_global_param > .rc-menu{
    border:none;
    background-color: transparent;
    height:1.6rem;
    width:1.5rem;
}

.D_avancee_global_param  .rc-menu-submenu{
  background-color: transparent;
}

#root .SM_avancee_param_menuitem{
    display:flex;
    background-color: transparent;
    color:#212121;
    padding: 0.25rem 0 0.25rem 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#212121;
    margin-right: 1.5rem;
}

#root .SM_avancee_param_menuitem_text{
    cursor:default;
    background-color: transparent;
    color:#212121;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}


/* custom input button */

.SM_avancee_param_menuitem.inactif,
.SM_avancee_param_menuitem.inactif label,
.SM_avancee_param_menuitem.inactif span,
.SM_avancee_param_menuitem.inactif input{
  cursor    : default;
  color     : #DDD; 
  background: #FFF;
}
.SM_avancee_param_menuitem.inactif label span {
  border    : 1px solid #CCC;
  background: #FFF !important;
}

.SM_avancee_param_menuitem.inactif:active{
pointer-events: none;
}

.SM_avancee_param_menuitem label {
    position: relative;
    padding-left: 1.4rem;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .SM_avancee_param_menuitem label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .SM_avancee_param_menuitem span {
    position        : absolute;
    top             : -1px;
    left            : 0;
    height          : 0.8rem;
    width           : 0.8rem;
    background-color: white;
    border          :1px solid #5a95aa;
  }
  
  /* On mouse-over, add a grey background color */
  .SM_avancee_param_menuitem label:hover input ~ span {
    background-color: #5a95aa;
  }
  
  /* When the checkbox is checked, add a blue background */
  .SM_avancee_param_menuitem label input:checked ~ span  {
    background-color: #5a95aa;
    color:white;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .SM_avancee_param_menuitem span:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .SM_avancee_param_menuitem label input:checked ~ span:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .SM_avancee_param_menuitem label span:after {
    left: 0.25rem;
    top: 0.05rem;
    width: 0.2rem;
    height: 0.4rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* input radio button */
  /* Customize the label (the container) */

  #root .SM_avancee_param_menuitem_radio{
    background-color: transparent;
    color:#212121;
    padding: 0.25rem 0 0.25rem 1rem;
    font-size: 1rem;
    color:#212121;
    margin-right: 1.5rem;
}

.SM_avancee_param_menuitem_radio label{
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  font-size: 1rem;
  color: #212121;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.SM_avancee_param_menuitem_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.SM_avancee_param_menuitem_radio span {
  position: absolute;
  top: 0.1rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color:transparent;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.SM_avancee_param_menuitem_radio label:hover input ~ span {
  background-color: #5a95aa;
}

/* When the radio button is checked, add a blue background */
.SM_avancee_param_menuitem_radio label input:checked ~ span {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.SM_avancee_param_menuitem_radio label span:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.SM_avancee_param_menuitem_radio label input:checked ~ span:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.SM_avancee_param_menuitem_radio label span:after {
  left: 0.25rem;
  top: 0;
  width: 0.3rem;
  height: 0.5rem;
  border: solid #5a95aa;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
  